import { all, fork, call, delay, put, takeLatest } from 'redux-saga/effects';

import { getServices, getProviders, createProviderRequest } from '../api/services';

import {
  GET_SERVICES,
  getServicesSuccess,
  getServicesFailure,
  GET_PROVIDERS,
  getProvidersSuccess,
  getProvidersFailure,
  CREATE_PROVIDER_REQUEST,
  createProviderRequestSuccess,
  createProviderRequestFailure,
} from '../actions/services_actions';

function* getServicesSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getServices);
    yield put(getServicesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getServicesFailure(processedError || error));
  }
}

function* getProvidersSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getProviders, action.serviceId);
    yield put(getProvidersSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getProvidersFailure(processedError || error));
  }
}

function* createProviderRequestSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(createProviderRequest, action.providerRequest.providerId, action.providerRequest.text);
    yield put(createProviderRequestSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(createProviderRequestFailure(processedError || error));
  }
}

const getServicesWatcher = function* getServicesWatcher() {
  yield takeLatest(GET_SERVICES.REQUEST, getServicesSaga);
};

const getProvidersWatcher = function* getProvidersWatcher() {
  yield takeLatest(GET_PROVIDERS.REQUEST, getProvidersSaga);
};

const createProviderRequestWatcher = function* createProviderRequestWatcher() {
  yield takeLatest(CREATE_PROVIDER_REQUEST.REQUEST, createProviderRequestSaga);
};

export default function* authSaga() {
  yield all([
    fork(getServicesWatcher),
    fork(getProvidersWatcher),
    fork(createProviderRequestWatcher),
  ]);
}
