import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Container, Row, Col, Button } from 'react-bootstrap';


import ic_plus from "../../assets/icons/ic_plus.svg";
import { Loader } from '../../components';
import { getMessages } from "../../store/actions/messages_actions";
import { setBlackboardOpend } from "../../store/actions/blackboard_action";
import MessageItem from './MessageItem';

import './BlackboardPage.scss';
import withRouter from "../../wrapper/withRouter";


class BlackboardPage extends Component {
  state = {
    page: 1,
    perPage: 10,
  }

  fetchData = () => {
    const { messages } = this.props;
    if (!messages.isFetching && navigator.onLine) {
      this.props.getMessages(this.state.page);
    }
  }

  componentDidMount() {
    const { user, setBlackboardOpend } = this.props

    this.fetchData();

    setBlackboardOpend(user.user.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { messages, router } = this.props;
    const { params } = router;
    const noMessages = !messages.messages.length;
    const currentPage = params.page || 1;
    const hasPageChanged = (params.page && params.page !== this.state.page) || (!params.page && this.state.page !== 1);

    if (!messages.isFetching && (noMessages || hasPageChanged) && currentPage !== prevState.page) {
      this.setState({ page: currentPage }, this.fetchData);
    }
  }

  createMessage = () => {
    const { router } = this.props;
    const { navigate } = router;
    navigate(`/messages/new`);
  }

  render() {
    const { messages } = this.props;

    if (messages.isFetching) {
      return <Loader />;
    }

    return (
      <div className="blackboard-page">
        <Container>
          <Row>
            <Col md={12}>
              <Button onClick={this.createMessage}>
                <img src={ic_plus} alt="" className="ic-plus"/>
                Neuer Beitrag
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul>
                {messages.messages
                  .sort((a, b) => a.created_at < b.created_at ? 1 : -1)
                  .map((message, idx) =>
                    <MessageItem key={message.id} message={message}/>
                  )
                }
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

BlackboardPage.propTypes = {
}

BlackboardPage.defaultProps = {
}

const mapStateToProps = state => ({
  user: state.user,
  messages: state.messages,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getMessages,
    setBlackboardOpend,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlackboardPage));
