import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { PersistGate } from 'redux-persist/integration/react';

import { store, runSaga, persistor, reduxHistory as history } from './store/storeConfig';
import rootSaga from './store/sagas';
import Routes from './layout/routes';
import { app, analytics } from './components/firebase';
import { Loader } from './components';

import './App.scss';
// import './constants/fonts.scss';

runSaga(rootSaga);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Router history={history}>
            <Routes />
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
