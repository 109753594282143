import {
  GET_MESSAGES,
  GET_COMMENTS,
  CREATE_MESSAGE,
  LIKE_MESSAGE,
  CREATE_COMMENT,
  CREATE_MESSAGE_DOCUMENT,
} from '../actions/messages_actions';

const initialState = {
  messages: [],
  comments: {},
  count: 0,
  selectedMessage: null,
  isFetching: false,
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_MESSAGES.SUCCESS:
      return {
        ...state,
        messages: action.data.results,
        count: action.data.count,
        isFetching: false,
      };
    case GET_MESSAGES.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case GET_COMMENTS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_COMMENTS.SUCCESS:
      let comment = {};
      comment[action.data.id] = action.data.results;
      return {
        ...state,
        comments: {...state.comments, ...comment},
        isFetching: false,
      };
    case GET_COMMENTS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case CREATE_MESSAGE.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_MESSAGE.SUCCESS:
      return {
        ...state,
        //not updated due to moderation process
        //messages: [...state.messages, action.data],
        selectedMessage: action.data.id,
        isFetching: false,
      };
    case CREATE_MESSAGE.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case LIKE_MESSAGE.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIKE_MESSAGE.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case LIKE_MESSAGE.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case CREATE_COMMENT.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_COMMENT.SUCCESS:
      return {
        ...state,
        //not updated due to moderation process
        //comments: [...state.comments, action.data],
        isFetching: false,
      };
    case CREATE_COMMENT.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case CREATE_MESSAGE_DOCUMENT.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_MESSAGE_DOCUMENT.SUCCESS:
      // const message = state.messages.find(message => message.id === state.selectedMessage);
      // const pos = state.messages.indexOf(message);
      return {
        ...state,
        //not updated due to moderation process
        // messages:
        //   state.messages.slice(0,pos)
        //     .concat([{...message, documents: [...message.documents, ...action.data.files]}])
        //     .concat(state.messages.slice(pos+1)),
        isFetching: false,
      };
    case CREATE_MESSAGE_DOCUMENT.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
      
    default:
      return state;
  }
}
