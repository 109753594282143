import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';

import { getLocation } from '../api/location';

import {
  GET_LOCATION,
  getLocationSuccess,
  getLocationFailure,
} from '../actions/location_actions';

function* getLocationSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getLocation);
    yield put(getLocationSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getLocationFailure(processedError || error));
  }
}

const getLocationWatcher = function* getLocationWatcher() {
  yield takeLatest(GET_LOCATION.REQUEST, getLocationSaga);
};

export default function* authSaga() {
  yield all([
    fork(getLocationWatcher),
  ]);
}
