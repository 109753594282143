import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getServices } from "../../store/actions/services_actions";
import { getLocation } from "../../store/actions/location_actions";
import { getProfile } from "../../store/actions/user_actions";
import { getBlackboardOpend } from "../../store/actions/blackboard_action"
import { getMessages } from "../../store/actions/messages_actions";
import { store } from "../../store/storeConfig"

import Feature1Svg from '../../assets/icons/ic_phone.svg'
import Feature2Svg from '../../assets/icons/ic_reception.svg'
import Feature3Svg from '../../assets/icons/ic_blackboard.svg'
import Feature4Svg from '../../assets/icons/ic_service.svg'
import Feature5Svg from '../../assets/icons/ic_administration.svg'
import Feature6Svg from '../../assets/icons/ic_home.svg'
import 'bootstrap/dist/css/bootstrap.css';

import "./HomePage.scss";
import withRouter from "../../wrapper/withRouter";

const getFeatures = (idObj) => {
  const features = [
    {
      img: Feature1Svg,
      captionId: "important_phone_number",
      route: "/phone-numbers",
    },
    {
      img: Feature2Svg,
      captionId: "reception",
      route: "/services/3",
    },
    {
      img: Feature3Svg,
      captionId: "blackboard",
      route: "/blackboard",
    },
    {
      img: Feature4Svg,
      captionId: "services",
      route: "/services",
    },
  ];

  if (idObj.bonvid) {
    features.push({
      img: Feature5Svg,
      captionId: "bonvid",
      route: "/services/1",
    });
  }

  features.push({
    img: Feature6Svg,
    captionId: "apartment",
    route: "/apartment",
  });

  return features;
};

class HomePage extends Component {
  state = {};

  async componentDidMount() {
    const { services, loc, user, messages, blackboardOpened } = this.props;

    if (!user.isFetching && navigator.onLine) {
      this.props.getProfile();
    }

    if (!services.isFetching && navigator.onLine) {
      this.props.getServices();
    }

    if (!loc.isFetching && navigator.onLine) {
      this.props.getLocation();
    }

    if (!messages.isFetching && navigator.onLine) {
      this.props.getMessages(this.state.page);
    }

    if (!blackboardOpened.isFetching && user.user && navigator.onLine) {
      this.props.getBlackboardOpend(user.user.id);
    }
  }

  renderSwitch(param) {
    switch(param) {
      case 'contact_reception':
        return 'Rezeption kontaktieren';
      case 'contact_helpdesk':
        return 'Belano Helpdesk';
      case 'important_phone_number':
        return 'Wichtige Telefonnummern';
      case 'apartment':
        return 'Meine Wohnung';
      case 'blackboard':
        return 'Pinnwand';
      case 'services':
        return 'Dienstleistungen';
      case 'reception':
        return 'Rezeption';
      case 'bonvid':
        return 'Verwaltung';
      default:
        return 'App'
    }
  }

  getNotificationCount() {
    const { messages, blackboardOpened } = this.props;

    
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 3)
    
    const dateMinusThreeDays = dateNow.toISOString()
    
    let count = 0;
    
    messages.messages.forEach((message => {
      if ((message.created_at > blackboardOpened.blackboardOpened) && (message.created_at > dateMinusThreeDays)) {
        count++;
      }
    }))

    if (count > 99) {
      return '99+'
    } else if (count > 0) {
      return String(count)
    } else {
      return null
    }
  }

  goToProvider = () => {
    const { services, router } = this.props;
    const { navigate } = router;
    const defaultService = services.services[0];
    navigate(`/services/${defaultService.id}`);
  };

  goToDefaultService = () => {
    const { router } = this.props;
    const { navigate } = router;
    navigate(`/services/${this.props.loc.location.default_service_id}`);
  };

  render() {
    const { services, loc, user, messages, router } = this.props;
    const { navigate } = router;
    const servicesIdObj = {};
    const notificationCount = this.getNotificationCount();
    let emergencyServicesDeactivated = true;

    if (user.user) {
      emergencyServicesDeactivated = user.user.emergency_services_deactivated;
    }

    if (services.services.length) {
      servicesIdObj.bonvid = services.services[services.services.length - 1];
    }

    return (
      <div className="homepage">
        <Container>
          <Row>
            <Col md={12}>
              {!emergencyServicesDeactivated && loc.location && !loc.location.direct_call && (
                <Button className="btn btn-emergency" type="button" onClick={this.goToDefaultService}>{loc.location.default_service_name}</Button>
              )}
              {!emergencyServicesDeactivated && loc.location && loc.location.direct_call && (
                <a href={`tel:${loc.location.direct_number}`}>
                  <Button className="btn btn-emergency" type="button">{loc.location.default_service_name}</Button>
                </a>
              )}
            </Col>
          </Row>
          <Row>
            {getFeatures(servicesIdObj).map((feature, idx) => (
              <Col md={4} key={idx}>
                <div className="feature-wrapper" onClick={() => navigate(feature.route)}>
                    <img src={feature.img} alt="" />
                    {(feature.captionId === 'blackboard' && notificationCount) && (
                      <span className="badge rounded-pill">
                        <span>{notificationCount}</span>
                      </span>
                    )}

                    <div className="feature-caption">
                      {this.renderSwitch(feature.captionId)}
                    </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

HomePage.propTypes = {};

HomePage.defaultProps = {};

const mapStateToProps = (state) => {
  return ({
    services: state.services,
    loc: state.location,
    user: state.user,
    messages: state.messages,
    blackboardOpened: state.blackboard,
  });
}

const mapDispatchToProps = {
  getServices,
  getLocation,
  getProfile,
  getMessages,
  getBlackboardOpend,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage));
