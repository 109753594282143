import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';

import AuthService from "../../services/auth.service";
import { getProfile, updateProfile, changePassword } from '../api/user';
import { forgotPasswordConfirm } from '../api/auth';

import {
  GET_PROFILE,
  getProfileSuccess,
  getProfileFailure,
  UPDATE_PROFILE,
  updateProfileSuccess,
  updateProfileFailure,
  CHANGE_PASSWORD,
  changePasswordSuccess,
  changePasswordFailure,
} from '../actions/user_actions';

function* getProfileSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getProfile);
    yield put(getProfileSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getProfileFailure(processedError || error));
  }
}

function* updateProfileSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(updateProfile, action.profileData);
    yield put(updateProfileSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(updateProfileFailure(processedError || error));
  }
}

function* changePasswordSaga(action) {
  yield delay( 500);
  try {
    let data;
    if (AuthService.isAuthenticated) {
      ({ data } = yield call(changePassword, action.passwordData));
    } else {
      ({ data } = yield call(forgotPasswordConfirm, action.passwordData));
    }
    yield put(changePasswordSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(changePasswordFailure(processedError || error));
  }
}

const getProfileWatcher = function* getProfileWatcher() {
  yield takeLatest(GET_PROFILE.REQUEST, getProfileSaga);
};

const updateProfileWatcher = function* updateProfileWatcher() {
  yield takeLatest(UPDATE_PROFILE.REQUEST, updateProfileSaga);
};

const changePasswordWatcher = function* changePasswordWatcher() {
  yield takeLatest(CHANGE_PASSWORD.REQUEST, changePasswordSaga);
};

export default function* authSaga() {
  yield all([
    fork(getProfileWatcher),
    fork(updateProfileWatcher),
    fork(changePasswordWatcher),
  ]);
}
