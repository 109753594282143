import React, { Component } from 'react';
import classnames from 'classnames';
import ic_see from '../../assets/icons/ic_see.svg';
import ic_hide from '../../assets/icons/ic_hide.svg';
import './styles.scss';

class Input extends Component {
  state = {
    isPasswordVisible: false,
  };

  handleClick = () => {
    const { onClick, prefillValue, onChange } = this.props;
    if (onChange && prefillValue) {
      onChange({ target: { value: prefillValue } });
    }
    if (onClick) {
      onClick();
    }
  };

  render() {
    const {
      type,
      placeholder,
      styles,
      value,
      name, // Add name prop here
      disabled,
      onChange,
      noStyle,
    } = this.props;
    const { isPasswordVisible } = this.state;

    return (
      <span className="inputWrapper">
        <input
          name={name} // Ensure the name prop is set here
          onClick={this.handleClick}
          onChange={onChange}
          type={type === 'password' && isPasswordVisible ? 'text' : type}
          className={classnames({
            'input': !noStyle,
            'has-value': value,
          })}
          disabled={disabled}
          placeholder={placeholder}
          style={styles}
          value={value}
        />
        <span className="inline-label">{placeholder}</span>
        {this.props.error && (
          <div className="error">
            {this.props.error}
          </div>
        )}
        {type === 'password' && (
          <div
            className="eye-image"
            style={{ backgroundImage: `url(${isPasswordVisible ? ic_hide : ic_see})` }}
            onClick={() => {
              this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
            }}
          />
        )}
      </span>
    );
  }
}

export default Input;
