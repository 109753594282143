import createRequestTypes from '../../components/requestsTypes';

export const GET_DOCUMENTS = createRequestTypes('GET_DOCUMENTS');

export function getDocuments() {
  return {
    type: GET_DOCUMENTS.REQUEST,
  }
}

export function getDocumentsSuccess(data) {
  return {
    type: GET_DOCUMENTS.SUCCESS,
    data
  }
}

export function getDocumentsFailure(error) {
  return {
    type: GET_DOCUMENTS.FAILURE,
    error,
  }
}
