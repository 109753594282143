import { GET_SERVICES, GET_PROVIDERS, CREATE_PROVIDER_REQUEST } from "../actions/services_actions";

const initialState = {
  services: [],
  providers: {},
  isFetching: false,
  error: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SERVICES.SUCCESS:
      return {
        ...state,
        services: action.data.results,
        isFetching: false,
      };
    case GET_SERVICES.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case GET_PROVIDERS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PROVIDERS.SUCCESS:
      let provider = {};
      provider[action.data.id] = action.data.results;
      return {
        ...state,
        providers: { ...state.providers, ...provider },
        isFetching: false,
      };
    case GET_PROVIDERS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case CREATE_PROVIDER_REQUEST.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_PROVIDER_REQUEST.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_PROVIDER_REQUEST.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
}
