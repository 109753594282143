import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import documentsReducer from './documents_reducer';
import phonesReducer from './phones_reducer';
import servicesReducer from './services_reducer';
import locationReducer from './location_reducer';
import messagesReducer from './messages_reducer';
import blackboardReducer from './blackboard_reducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'authenticated'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['user'],
};

const documentsPersistConfig = {
  key: 'documents',
  storage,
  whitelist: ['documents'],
};

const phonesPersistConfig = {
  key: 'phones',
  storage,
  whitelist: ['phones'],
};

const servicesPersistConfig = {
  key: 'services',
  storage,
  whitelist: ['services', 'providers'],
};

const locationPersistConfig = {
  key: 'location',
  storage,
  whitelist: ['location'],
};

const messagesPersistConfig = {
  key: 'messages',
  storage,
  whitelist: ['messages', 'comments', 'count'],
};

const blackboardPersistConfig = {
  key: 'blackboard',
  storage,
  whitelist: ['blackboard'],
}

export default {
  auth: persistReducer(authPersistConfig, authReducer),
  user: persistReducer(userPersistConfig, userReducer),
  documents: persistReducer(documentsPersistConfig, documentsReducer),
  phones: persistReducer(phonesPersistConfig, phonesReducer),
  services: persistReducer(servicesPersistConfig, servicesReducer),
  location: persistReducer(locationPersistConfig, locationReducer),
  messages: persistReducer(messagesPersistConfig, messagesReducer),
  blackboard: persistReducer(blackboardPersistConfig, blackboardReducer),
};
