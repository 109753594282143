import createRequestTypes from '../../components/requestsTypes';

export const SET_BLACKBOARD_OPEND = createRequestTypes('SET_BLACKBOARD_OPEND');
export const GET_BLACKBOARD_OPEND = createRequestTypes('GET_BLACKBOARD_OPEND');


export function getBlackboardOpend(userId) {
  return {
    type: GET_BLACKBOARD_OPEND.REQUEST,
    userId,
  }
}
export function getBlackboardOpendSuccess(data) {
  return {
    type: GET_BLACKBOARD_OPEND.SUCCESS,
    data,
  }
}
export function getBlackboardOpendFailure(error) {
  return {
    type: GET_BLACKBOARD_OPEND.FAILURE,
    error,
  }
}


export function setBlackboardOpend(userId) {
  return {
    type: SET_BLACKBOARD_OPEND.REQUEST,
    userId,
  }
}
export function setBlackboardOpendSuccess(data) {
  return {
    type: SET_BLACKBOARD_OPEND.SUCCESS,
    data,
  }
}
export function setBlackboardOpendFailure(error) {
  return {
    type: SET_BLACKBOARD_OPEND.FAILURE,
    error,
  }
}