import AuthService from '../../services/auth.service';
import { toast } from 'react-toastify';

export default async (response) => {
  if (!navigator.onLine) {
    //toast('Network unavailable, try later.');
    throw new Error('Network unavailable, try later.');
  }
  const res = await response;
  const text = await res.text();
  let data;
  try {
    data = JSON.parse(text);
  } catch (e) {
    data = text;
  }
  if (res.ok) {
    return {
      data,
      error: null,
    };
  } else if (res.status === 401) {
    AuthService.clear();
    window.location.href = '/login';
  } else {
    throw new Error(JSON.stringify(data));
  }

  throw new Error(data.message || data.error);
};
