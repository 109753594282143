import React, { Component } from 'react';
import { Oval } from 'react-loader-spinner';
import './styles.scss';

class Loader extends Component {
  render() {
    return (
      <div className="loader-container">
        <Oval
          visible={true}
          height="100"
          width="100"
          color="#8a7252"
          secondaryColor="#bfa188"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }
}

export default Loader;
