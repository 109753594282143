import React, { Component } from "react";
// import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { Img } from "../../components";

import { Textarea, Loader } from "../../components";
import { getServices, getProviders, createProviderRequest } from "../../store/actions/services_actions";
import { required } from "../../components/validators";

import PhoneImage from "../../assets/icons/ic_phone.svg";
import EmailImage from "../../assets/icons/ic_email.svg";
import WebsiteImage from "../../assets/icons/ic_globe.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./ServicesPage.scss";
import {getLocation} from "../../store/actions/location_actions";
import {getProfile} from "../../store/actions/user_actions";
import withRouter from "../../wrapper/withRouter";

class Service extends Component {
  constructor(props) {
    super(props);

    this.spanRef = React.createRef();
    this.divRef = React.createRef();
  }

  render() {
    const { service } = this.props;
    return (
      <div ref={this.divRef}>
        <img src={service.icon} alt="" />
        <span ref={this.spanRef}>{service.title}</span>
      </div>
    );
  }
}

class ServicesPage extends Component {
  constructor(props) {
    super(props);

    this.required = required("Eingabe ist zwingend");

    this.state = {
      request: '',
      isRequesting: false,
      requestSent: false,
      firstRequest: false,
      show: false,
      isValid: false,
    };
  }


  fetchData = () => {
    const { services, loc, user } = this.props;
    if (!services.isFetching && navigator.onLine) {
      this.props.getServices();
    }

    if (!loc.isFetching && navigator.onLine) {
      this.props.getLocation();
    }

    if (!user.isFetching && navigator.onLine) {
      this.props.getProfile();
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { services } = this.props;
    const { isRequesting } = this.state;

    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.required = required("Eingabe ist zwingend");
    }
    if (isRequesting && !services.isFetching && prevProps.services.isFetching && !services.error) {
      this.setState({ isRequesting: false, requestSent: false, firstRequest: true, show: false });

      toast.success("Ihre Anfrage wurde erfolgreich versendet.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  openProvider(providerId) {
    const { router } = this.props;
    const { navigate } = router;
    let url = `/services/${providerId}`;
    if (providerId === 3 || providerId === 14 || providerId === 1) {
      url += "?fd=1";
    }

    navigate(url);
    this.setState({ firstRequest: false });
  }

  handleChange = (value) => {
    this.setState({
      request: value,
      isValid: value.trim().length > 0,
    });
  };

  handleSubmit = (e) => {
  e.preventDefault();
  const { requestSent, request } = this.state;
  const { createProviderRequest, params } = this.props;

  if (request.trim().length === 0) {
    this.setState({ isValid: false });
    return;
  }

  if (requestSent) {
    this.setState({ requestSent: false, firstRequest: false });
  } else {
    this.setState({ isRequesting: true });
    createProviderRequest({
      providerId: params.serviceId,
      text: request,
    }).then(() => {
      this.setState({ isRequesting: false, requestSent: true });
    })
  }
}

  handleClose = () => {
    this.setState({ show: false, isRequesting: false });
  };

  replaceInputTextParameters = (text) => {
    const { user, loc } = this.props;

    const profileNameReplace = "{profile_name}";
    const profileNameIndex = text.indexOf(profileNameReplace);

    if (profileNameIndex !== -1) {
      text =
        text.slice(0, profileNameIndex) + user.user.name + text.slice(profileNameIndex + profileNameReplace.length);
    }

    const appartmentNumberReplace = "{apartment_number}";
    const appartmentNumberIndex = text.indexOf(appartmentNumberReplace);

    if (appartmentNumberIndex !== -1) {
      let apartment_number =
        user.user.apartment_number.split("-")[1] !== undefined
          ? user.user.apartment_number.split("-")[1]
          : user.user.apartment_number;

      text =
        text.slice(0, appartmentNumberIndex) +
        apartment_number +
        text.slice(appartmentNumberIndex + appartmentNumberReplace.length);
    }

    const locationReplace = "{location}";
    const locationIndex = text.indexOf(locationReplace);

    if (locationIndex !== -1) {
      text = text.slice(0, locationIndex) + loc.location.name + text.slice(locationIndex + locationReplace.length);
    }

    return text;
  };

  getInputEmptyText = () => {
    const { services, router } = this.props;
    const { params } = router;

    const serviceId = params.serviceId;
    const service = services.services.find((service) => service.id === +serviceId);
    const placeholderReplace = "{placeholder}";
    let text = service.contact_text.split(placeholderReplace).join("");

    return this.replaceInputTextParameters(text);
  };

  getInputPlaceholder = () => {
    const { services, router } = this.props;
    const { params } = router;

    const serviceId = params.serviceId;
    const service = services.services.find((service) => service.id === +serviceId);
    let inputEmptyText = this.replaceInputTextParameters(service.contact_text);
    const lineFeedSymbol = String.fromCharCode(10);
    const placeholderReplace = "{placeholder}";

    const concerns =
      serviceId && services.providers[serviceId][0].placeholder
        ? services.providers[serviceId][0].placeholder
        : "Hier bitte Anliegen beschreiben.";

    const arrayToRender = inputEmptyText.split(lineFeedSymbol).reduce((acc, item, index) => {
      const placeholderIndex = item.indexOf(placeholderReplace);

      if (placeholderIndex !== -1) {
        acc.push(item.slice(0, placeholderIndex));
        acc.push(<span key={`item${index}`}>{concerns}</span>);
        acc.push(item.slice(placeholderIndex + placeholderReplace.length));
      } else {
        acc.push(item);
      }

      acc.push(<br key={index} />);
      return acc;
    }, []);

    return <span>{arrayToRender}</span>;
  };

  render() {
    const { user, services, router, valid, handleSubmit, reset, handleClose, loc } = this.props;
    const { params } = router;
    const { request, isRequesting, requestSent, show, isValid, firstRequest } = this.state;

    if (!user.user || (params.serviceId && !services.providers[params.serviceId])) {
      return <Loader />;
    }

    let formButtonTranslateId = firstRequest ? "Neue Anfrage schicken" : "Anfrage absenden";

    const providers = services.providers[params.serviceId];
    // todo fix this.props.dispatch error
    // if (providers !== undefined && providers.length > 0) {
    //   this.props.dispatch("providerForm", "providerId", providers[0].id);
    // }

    let emergencyServicesDeactivated = true;

    if (user.user) {
      emergencyServicesDeactivated = user.user.emergency_services_deactivated;
    }

    return params.serviceId ? (
      <React.Fragment>
        <div className="provider-info">
          <form className="providerForm" onSubmit={this.handleSubmit}>
            <Container>
              <Row>
                <Col md={12}>
                  <ul>
                    {providers.map((provider) => (
                      <li key={provider.id}>
                        {provider.name !== 'Belano Helpdesk' && <h3 className="title">{provider.name}</h3>}

                        {provider.phone && provider.name === 'Belano Helpdesk' && (
                          <p>
                            <a href={`tel:${provider.phone}`}>
                              <button type="button" className="btn btn-primary">
                                {provider.hide_phone ? 'Anrufen' : provider.phone}
                              </button>
                            </a>
                            <br />
                            <br />
                          </p>
                        )}

                        {/* Provider description section */}
                        {provider.text &&
                          provider.text.split('\n').map((item, key) => (
                            <p className="text" key={key}>
                              {item}
                              <br />
                            </p>
                          ))}

                        {/* Provider contact section */}
                        {provider.contact && (
                          <p className="contact">
                            <b>Kontakt</b>
                            <br />
                            {provider.contact.split('\n').map((item, key) => (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            ))}
                          </p>
                        )}

                        {/* Provider hours section */}
                        {provider.hours && (
                          <p className="hours">
                            <b>Öffnungszeiten</b>
                            <br />
                            {provider.hours.split('\n').map((item, key) => (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            ))}
                          </p>
                        )}

                        {/* Provider buttons */}
                        {providers.length &&
                          (provider.phone || provider.website || (!(providers.length === 1 && providers[0].allow_form_contact) && provider.email)) && (
                            <div className="btn-wrapper">
                              {/* Phone button */}
                              {provider.phone && provider.name !== 'Belano Helpdesk' && (
                                <a href={`tel:${provider.phone}`} className="phone">
                                  <button className="btn btn-primary" type="button">
                                    <Img altSrc="Phone" srcSet={PhoneImage} />
                                    {provider.hide_phone ? 'Anrufen' : provider.phone}
                                  </button>
                                </a>
                              )}

                              {/* Email button */}
                              {!(providers.length === 1 && providers[0].allow_form_contact) && provider.email && (
                                <a className="email">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      this.setState({ show: true });
                                    }}
                                  >
                                    <Img altSrc="Email" srcSet={EmailImage} />
                                    Email
                                  </button>
                                </a>
                              )}

                              {/* Website button */}
                              {provider.website && (
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={`${provider.website}`}
                                  className="website"
                                >
                                  <button className="btn btn-primary" type="button">
                                    <Img altSrc="Website" srcSet={WebsiteImage} />
                                    Webseite
                                  </button>
                                </a>
                              )}
                            </div>
                          )}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
              {providers.length === 1 && providers[0].allow_form_contact && (
                <>
                  <Row>
                    <Col md={12}>
                      {!requestSent && (
                        <Textarea
                          name="request"
                          value={request}
                          onChange={this.handleChange}
                          placeholderText={this.getInputPlaceholder()}
                          emptyValue={this.getInputEmptyText()}
                        />
                      )}
                      {requestSent && (
                        <div className="done-fetching">
                          Vielen Dank für Ihre Anfrage. Wir werden uns schnellstmöglich bei Ihnen melden.
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button type="submit" disabled={!isValid || isRequesting}>
                        {formButtonTranslateId}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </form>
        </div>
        <Modal
          show={show}
          onHide={() => {
            this.setState({ show: false });
          }}
          className="contact-modal"
        >
          <form className="providerForm" onSubmit={this.handleSubmit}>
            <Modal.Header>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ show: false });
                }}
              >
                Abbrechen
              </Button>
              <Button variant="primary" type="submit" disabled={!isValid || isRequesting}>
                Anfrage absenden
              </Button>
            </Modal.Header>
            <Modal.Body>
              <Textarea
                name="request"
                value={request}
                onChange={this.handleChange}
                placeholderText={this.getInputPlaceholder()}
                emptyValue={this.getInputEmptyText()}
              />
            </Modal.Body>
          </form>
        </Modal>
        <ToastContainer />
      </React.Fragment>
    ) : (
      <div className="services">
        <Container>
          <Row>
            <Col md={12} className="text">
              Klicken Sie auf die gewünschte Dienstleistung, um weitere Informationen zu erhalten.
            </Col>
          </Row>
          <Row>
            <ul>
              {services.services.filter(function (service) {
                const provider = services.providers[service.id];
                return !(
                  emergencyServicesDeactivated &&
                  provider[0].phone.replace(/ /g, '') === loc.location.direct_number.replace(/ /g, '')
                );
              }).map((service) => (
                <li key={service.id} onClick={() => this.openProvider(service.id)}>
                  <Service service={service} />
                </li>
              ))}
            </ul>
          </Row>
        </Container>
      </div>
    );
  }
}

ServicesPage.propTypes = {};

ServicesPage.defaultProps = {};

const mapStateToProps = (state) => ({
  services: state.services,
  user: state.user,
  loc: state.location,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getServices,
      getProviders,
      getLocation,
      createProviderRequest,
      getProfile,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ServicesPage));


