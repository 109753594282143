import React, { Component } from 'react';
import moment from "moment";
import { Button } from "react-bootstrap";
import {bindActionCreators} from "redux";
import { connect } from "react-redux";

import { Gallery } from "../../components";
import ic_thumpsupfilled from "../../assets/icons/ic_thumpsupfilled.svg";
import ic_thumpsup from "../../assets/icons/ic_thumpsup.svg";
import ic_commentfilled from "../../assets/icons/ic_commentfilled.svg";
import ic_comment from "../../assets/icons/ic_comment.svg";
import { getMessages, likeMessage } from "../../store/actions/messages_actions";
import ic_user from "../../assets/icons/ic_user.svg";

import './MessageItem.scss';
import withRouter from "../../wrapper/withRouter";


class MessageItem extends Component {
  state = {
    isLiking: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isLiking && !prevProps.messages.isFetching && !this.props.messages.isFetching) {
      this.props.getMessages();
      this.setState({isLiking: false})
    }
  }

  likeMessage = async () => {
    const { message, likeMessage } = this.props;

    this.setState({isLiking: true}, () => {
      likeMessage({
        messageId: message.id,
        isLike: !message.self_liked
      })
    })
  }

  openMessage = ({ hash = '' }) => {
    const { router } = this.props;
    const { navigate } = router;
    navigate(`/messages/view/${this.props.message.id}${hash}`);
  }

  render() {
    const { message } = this.props;
    const { isLiking } = this.state;

    const profilePic = message.user_image || ic_user;

    return (
      <li className="message-item">
        <div className="user-info" onClick={this.openMessage}>
          <div className="user-img">
            <div style={({backgroundImage: `url(${profilePic})`})} />
          </div>
          <div className="user-details">
            <span className="user-name">
              {message.user_name}
            </span>
            <span className="message-date">
              {moment(message.created_at).format('HH:mm, DD.MM.YYYY')}
            </span>
          </div>
        </div>
        <div className="message-text" onClick={this.openMessage}>
          {
            message.text.length > 150
              ? (message.text.substr(0, 150) + '…').split('\n').map((item, key) =>
                <span key={key}>
                  {item}
                  <br/>
                </span>
              )
              : message.text.split('\n').map((item, key) =>
                <span key={key}>
                  {item}
                  <br/>
                </span>
              )
          }
        </div>
        <div className="message-attachments">
          <Gallery documents={message.documents} hasPreview />
        </div>
        <div className="message-social">
          <Button className="message-likes" onClick={this.likeMessage} disabled={isLiking}>
            <img src={message.self_liked ? ic_thumpsup : ic_thumpsupfilled} alt=""/>
            {document.body.clientWidth > 500 ? `${message.like_count} Likes` : message.like_count}
          </Button>
          <Button
            className="message-comments"
            onClick={() => this.openMessage({ hash: '#newComment' })}
          >
            <img src={message.self_commented ? ic_comment : ic_commentfilled} alt=""/>
            {document.body.clientWidth > 500 ? `${message.comment_count} Kommentare` : message.comment_count}
          </Button>
        </div>
      </li>
    )
  }
}

const mapStateToProps = state => ({
  messages: state.messages,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getMessages,
    likeMessage,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageItem));
