export { default as COLORS } from "./colors";
export const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL || "https://api.belano.ch" : process.env.REACT_APP_DEV_API_URL || "http://localhost:3001";
export const RESET_TIMEOUT = process.env.REACT_APP_RESET_TIMEOUT || 1000 * 60 * 60;
export const GA_KEY = process.env.REACT_APP_GA_KEY || "UA-139061385-1";
export const FILE_SIZE_LIMIT = process.env.REACT_APP_FILE_SIZE_LIMIT || 1 * 1024 * 1024;

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBt78uxIMp4kRGiJOG4drU9bQUw248bPH0",
  authDomain: "belano-975ef.firebaseapp.com",
  databaseURL: "https://belano-975ef.firebaseio.com",
  projectId: "belano-975ef",
  storageBucket: "belano-975ef.appspot.com",
  messagingSenderId: "294728097873",
  appId: "1:294728097873:web:1c571b6f5d6cb313",
  measurementId: "G-0YJY7TVW9M"
};

