import { all, fork, call, delay, put, takeLatest } from 'redux-saga/effects';

import {
  getMessages,
  getComments,
  createMessage,
  likeMessage,
  unlikeMessage,
  createComment,
  createMessageDocument,
} from '../api/messages';

import {
  GET_MESSAGES,
  getMessagesSuccess,
  getMessagesFailure,
  GET_COMMENTS,
  getCommentsSuccess,
  getCommentsFailure,
  CREATE_MESSAGE,
  createMessageSuccess,
  createMessageFailure,
  LIKE_MESSAGE,
  likeMessageSuccess,
  likeMessageFailure,
  CREATE_COMMENT,
  createCommentSuccess,
  createCommentFailure,
  CREATE_MESSAGE_DOCUMENT,
  createMessageDocumentSuccess,
  createMessageDocumentFailure,
} from '../actions/messages_actions';

function* getMessagesSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getMessages, action.page || 1);
    yield put(getMessagesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getMessagesFailure(processedError || error));
  }
}

function* getCommentsSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getComments, action.messageId);
    yield put(getCommentsSuccess({...data, id: action.messageId}));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getCommentsFailure(processedError || error));
  }
}

function* createMessageSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(createMessage, action.message);
    yield put(createMessageSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(createMessageFailure(processedError || error));
  }
}

function* createCommentSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(createComment, action.commentData.messageId, {text: action.commentData.text});
    yield put(createCommentSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.comment);
    } catch (e) {}
    yield put(createCommentFailure(processedError || error));
  }
}

function* likeMessageSaga(action) {
  yield delay( 100);
  try {
    const { data } = yield call(action.likeData.isLike ? likeMessage : unlikeMessage, action.likeData.messageId);
    yield put(likeMessageSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.comment);
    } catch (e) {}
    yield put(likeMessageFailure(processedError || error));
  }
}

function* createMessageDocumentSaga(action) {
  yield delay( 500);
  try {
    let result = [];
    for (let idx = 0; idx < action.filesData.files.length; idx++) {
      const name = action.filesData.files[idx].name.replace(/(\.[^\.]*)$/,'');
      const document = action.filesData.files[idx];
      const { data } = yield call(createMessageDocument, action.filesData.messageId, {name, document});
      result.push(data);
    }
    yield put(createMessageDocumentSuccess({files: result}));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(createMessageDocumentFailure(processedError || error));
  }
}

const getMessagesWatcher = function* getMessagesWatcher() {
  yield takeLatest(GET_MESSAGES.REQUEST, getMessagesSaga);
};

const getCommentsWatcher = function* getCommentsWatcher() {
  yield takeLatest(GET_COMMENTS.REQUEST, getCommentsSaga);
};

const createMessageWatcher = function* createMessageWatcher() {
  yield takeLatest(CREATE_MESSAGE.REQUEST, createMessageSaga);
};

const likeMessageWatcher = function* likeMessageWatcher() {
  yield takeLatest(LIKE_MESSAGE.REQUEST, likeMessageSaga);
};

const createCommentWatcher = function* createCommentWatcher() {
  yield takeLatest(CREATE_COMMENT.REQUEST, createCommentSaga);
};

const createMessageDocumentWatcher = function* createMessageDocumentWatcher() {
  yield takeLatest(CREATE_MESSAGE_DOCUMENT.REQUEST, createMessageDocumentSaga);
};

export default function* authSaga() {
  yield all([
    fork(getMessagesWatcher),
    fork(getCommentsWatcher),
    fork(createMessageWatcher),
    fork(likeMessageWatcher),
    fork(createCommentWatcher),
    fork(createMessageDocumentWatcher),
  ]);
}
