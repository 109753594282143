import { all, fork, call, delay, put, takeLatest } from 'redux-saga/effects';

import { getDocuments } from '../api/documents';

import {
  GET_DOCUMENTS,
  getDocumentsSuccess,
  getDocumentsFailure,
} from '../actions/documents_actions';

function* getDocumentsSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getDocuments);
    yield put(getDocumentsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getDocumentsFailure(processedError || error));
  }
}

const getDocumentsWatcher = function* getDocumentsWatcher() {
  yield takeLatest(GET_DOCUMENTS.REQUEST, getDocumentsSaga);
};

export default function* authSaga() {
  yield all([
    fork(getDocumentsWatcher),
  ]);
}
