import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';

import AuthService from "../../services/auth.service";
import { login } from '../../store/actions/auth_actions';
import { Input } from '../../components';
import logo from "../../assets/logos/belano_logo_new.jpeg";

import './LoginPage.scss';
import withRouter from "../../wrapper/withRouter";


class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apartment_number: '',
      password: '',
      isValid: false,
      isSubmitting: false,
      errorMessage: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    const { navigate } = router;
    if (AuthService.isAuthenticated) {
      navigate(`/`)
    }
    const doneAuthFetching = prevProps.auth.isFetching && !this.props.auth.isFetching;
    if (doneAuthFetching) {
      if (this.props.auth.authenticated) {
        navigate(`/`)
      } else {
        this.setState({
          isSubmitting: false
        })
        if (this.props.auth.error) {
          let errorMessage = '';
          if (this.props.auth.error.error === "Unknown apartment number") {
            errorMessage = 'Der Benutzername ist falsch, bitte überprüfen Sie die Schreibweise';
          }
          if (this.props.auth.error.error === "Invalid Credentials") {
            errorMessage = 'Das Passwort ist falsch, bitte überprüfen Sie die Schreibweise';
          }
          this.setState({ errorMessage });
        }
      }
    }
  }

  forgotPassword = (e) => {
    const { router } = this.props;
    const { navigate } = router;
    e.preventDefault();
    navigate(`/forgot-password`)
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.setState({
          isValid: this.state.apartment_number.trim().length > 0 && this.state.password.trim().length > 0,
        });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.isValid) {
      return;
    }

    this.setState({ isSubmitting: true });

    const { apartment_number, password } = this.state;

    const { router } = this.props;
    const { navigate } = router;
    if (AuthService.token) {
      navigate(`/`)
    } else {
      const values = {
        apartment_number,
        password,
      };
    this.props.login(values);
    }
  };

  render() {
    const { apartment_number, password, isValid, isSubmitting, errorMessage } = this.state;

    return (
      <div className="loginPage">
        <form className="loginForm" onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col md={12}>
                <img className="logo-image" src={logo} alt="logo" />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="apt-col">
                <Input
                  name="apartment_number"
                  type="text"
                  value={apartment_number}
                  onChange={this.handleChange}
                  placeholder="Benutzername"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="pwd-col">
                <Input
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.handleChange}
                  placeholder="Passwort"
                />
              </Col>
            </Row>
            {errorMessage && (
              <Row>
                <Col md={12}>
                  <div className="error">{errorMessage}</div>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12}>
                <Button type="submit" disabled={!isValid || isSubmitting}>
                  Anmelden
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <a href="/forgot-password" onClick={this.forgotPassword}>
                  Passwort vergessen?
                </a>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    );
  }
}

LoginPage.propTypes = {
  // actions
  login: PropTypes.func.isRequired,
};

LoginPage.defaultProps = {
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    login,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
