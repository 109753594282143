import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AuthService from "../../services/auth.service";
import { forgotPassword } from '../../store/actions/auth_actions';
import { changePassword } from '../../store/actions/user_actions';
import { Input } from '../../components';
import logo from "../../assets/logos/belano_logo_new.jpeg";
import './ForgotPasswordPage.scss';
import withRouter from "../../wrapper/withRouter";
import { required } from "../../components/validators";

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailSent: false,
      resetActivated: false,
      token: null,
      isFetching: false,
      doneFetching: false,
      apartment_number: '',
      new_password: '',
      repeat_password: '',
      passwordError: '',
      nonFieldError: '',
      usernameError: '',
      isValid: false,
    };

    this.required = required("Eingabe ist zwingend");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
        passwordError: '', // Clear password error on input change
        nonFieldError: '',
        usernameError: '',
      },
      () => {
        this.setState((prevState) => ({
          isValid: prevState.apartment_number.trim().length > 0 ||
                   prevState.new_password.trim().length > 0 ||
                   prevState.repeat_password.trim().length > 0,
        }));
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { forgotPassword, changePassword, router } = this.props;
    const { resetActivated, emailSent, token, doneFetching, apartment_number, repeat_password } = this.state;
    const { navigate } = router;

    if (!this.state.isValid) {
      return;
    }

    if (resetActivated && !doneFetching) {
      this.setState({ isFetching: true });
      changePassword({ token, password: repeat_password });
    } else if (resetActivated && doneFetching) {
      navigate(`/login`);
    } else if (!emailSent) {
      this.setState({ isFetching: true });
      forgotPassword({ apartment_number });
    } else {
      navigate(`/login`);
    }
  };

  componentDidMount() {
    if (this.props.router.params.token && !this.state.resetActivated) {
      this.setState({ resetActivated: true, token: this.props.router.params.token });
    }
  }

  componentDidUpdate(prevProps) {
    const { router, auth, user } = this.props;
    const { navigate } = router;

    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      this.required = required("Eingabe ist zwingend");
    }

    if (this.props.router.params.token && !this.state.resetActivated) {
      this.setState({ resetActivated: true, token: this.props.router.params.token });
    }

    const doneAuthFetching = prevProps.auth.isFetching && !auth.isFetching;
    if (this.state.isFetching && doneAuthFetching) {
      this.setState({ isFetching: false });
      if (!auth.error) {
        this.setState({ emailSent: true });
      } else {
        if (auth.error && auth.error.detail === "Nicht gefunden.") {
          this.setState({ usernameError: "Dieser Benutzername existiert nicht." });
        }
      }
    }

    const doneUserFetching = prevProps.user.isFetching && !user.isFetching;
    if (this.state.isFetching && doneUserFetching) {
      this.setState({ isFetching: false });
      if (!user.error) {
        this.setState({ doneFetching: true });
      } else {
        let passwordError = '';
        let nonFieldError = '';
        if (user.error.status === 'notfound') {
          passwordError = "Der Token ist ungültig";
        }
        if (user.error.non_field_errors && user.error.non_field_errors[0] === "Passwords do not match") {
          nonFieldError = "Die Passwörter stimmen nicht überein.";
        }
        this.setState({ passwordError, nonFieldError });
      }
    }

    if (AuthService.isAuthenticated) {
      navigate(`/`);
    }
  }

  render() {
    const { resetActivated, emailSent, doneFetching, isValid, isFetching, passwordError, nonFieldError, usernameError } = this.state;

    return (
      <div className="forgotPasswordPage">
        <form className="loginForm" onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col md={12}>
                <img className="logo-image" src={logo} alt="logo" />
              </Col>
            </Row>
            {!emailSent && !resetActivated && (
              <>
                <Row>
                  <Col md={12}>
                    <p className="description">
                      Um ein neues Passwort zu erhalten, geben Sie bitte Ihren Benutzernamen im unten stehenden Feld ein und klicken Sie auf „Neues Passwort“.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Input
                      name="apartment_number"
                      type="text"
                      value={this.state.apartment_number}
                      onChange={this.handleChange}
                      placeholder="Benutzername"
                      validate={this.required}
                      error={usernameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button type="submit" disabled={!isValid || isFetching}>
                      Neues Passwort
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {emailSent && !resetActivated && (
              <>
                <Row>
                  <Col md={12}>
                    <p className="description">
                      Besten Dank. Eine Email mit einem temporären Link wurde an ihre Email-Adresse geschickt. Der Link wird Sie zu einer neuen Seite führen, wo Sie ein neues Passwort erstellen können.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button type="submit">
                      Zurück zur Anmeldung
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {resetActivated && !doneFetching && (
              <>
                <Row>
                  <Col md={12}>
                    <Input
                      name="new_password"
                      type="password"
                      value={this.state.new_password}
                      onChange={this.handleChange}
                      placeholder="Neues Passwort"
                      validate={this.required}
                      error={passwordError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Input
                      name="repeat_password"
                      type="password"
                      value={this.state.repeat_password}
                      onChange={this.handleChange}
                      placeholder="Neues Passwort bestätigen"
                      validate={this.required}
                      error={nonFieldError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button type="submit" disabled={!isValid || isFetching}>
                      Neues Passwort speichern
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {resetActivated && doneFetching && (
              <>
                <Row>
                  <Col md={12}>
                    <p className="description">
                      Ihr Passwort wurde erfolgreich geändert.
                      <br />
                      Bitte melden Sie sich auf der Startseite neu an.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button type="submit">
                      Zurück zur Anmeldung
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </form>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  // react-router props
  params: PropTypes.object.isRequired,
  // actions
  forgotPassword: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

ForgotPasswordPage.defaultProps = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPassword,
      changePassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPasswordPage));
