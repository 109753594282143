import {API_URL} from '../../constants';
import http from './http-decorator';
import AuthService from '../../services/auth.service';


export const getDocuments = () => http(fetch(
  `${API_URL}/api/documents/`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));
