import { GET_BLACKBOARD_OPEND, SET_BLACKBOARD_OPEND } from '../actions/blackboard_action';

// let dateNow = new Date();
// dateNow.setDate(dateNow.getDate() - 3)
// const dateThreeDaysAgo = dateNow.toISOString();

const initialState = {
  blackboardOpened: null,
  isFetching: false,
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BLACKBOARD_OPEND.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_BLACKBOARD_OPEND.SUCCESS:
      return {
        ...state,
        blackboardOpened: action.data.date_blackboard_opened,
        isFetching: false,
      };
    case GET_BLACKBOARD_OPEND.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };        

    case SET_BLACKBOARD_OPEND.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SET_BLACKBOARD_OPEND.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case SET_BLACKBOARD_OPEND.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
      
    default:
      return state;
  }
}