import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import { Container, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import classnames from 'classnames';

import ic_thumpsup from "../../assets/icons/ic_thumpsup.svg";
import ic_thumpsupfilled from "../../assets/icons/ic_thumpsupfilled.svg";
import ic_comment from "../../assets/icons/ic_comment.svg";
import ic_commentfilled from "../../assets/icons/ic_commentfilled.svg";
import { Loader, Gallery, Textarea } from '../../components';
import { getMessages, getComments, createComment, likeMessage } from "../../store/actions/messages_actions";
import { required } from "../../components/validators";
import ic_user from "../../assets/icons/ic_user.svg";
import ic_user_white from "../../assets/icons/ic_user_white.svg";

import './MessagePage.scss';
import withRouter from "../../wrapper/withRouter";


class Comment extends Component {
  render() {
    const {comment} = this.props;

    const profilePic = comment.user_image || ic_user_white;

    return (
      <div className="comment">
        <div className={classnames("user-img", {
            'has-profile-image': comment.user_image
          })}
        >
          <img style={({backgroundImage: `url(${profilePic})`})} alt="" />
        </div>
        <div className="comment-data">
          <div className="user-details">
            <span className="user-name">
              {comment.user_name}
            </span>
            <span className="message-date">
              {moment(comment.created_at).format('HH:mm, DD.MM.YYYY')}
            </span>
          </div>
          <div className="comment-text">
            {comment.text.split('\n').map((item, key) =>
              <span key={key}>
                {item}
                <br/>
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class BlackboardPage extends Component {
  constructor(props) {
    super(props);

    this.required = required("Eingabe ist zwingend");

    this.commentRef = React.createRef();

    this.state = {
      comment: '',
      isValid: false,
      isSubmitting: false,
      isSubmittingDone: false,
      isLiking: false,
    };
  }

  fetchData = () => {
    const { messages } = this.props;
    if (!messages.messages.length && !messages.isFetching && navigator.onLine) {
      this.props.getMessages();
    }
  }

  fetchComments = () => {
    const { router, messages } = this.props;
    const { params } = router;
    const messageId = params.messageId;
    if (!messages.isFetching && navigator.onLine) {
      this.props.getComments(messageId);
    }
  }

  scrollToComments = (hash) => {
    const hashTarget = document.getElementById(hash.slice(1));
    const header = document.getElementsByClassName('header')[0];
    if (hashTarget) {
      window.scrollTo({ top: hashTarget.offsetTop - header.offsetHeight });
    }
  }

  componentDidMount() {
    const { router } = this.props;
    const { location } = router;

    if (location.hash) {
      setTimeout(() => this.scrollToComments(location.hash), 0);
    }

    this.fetchComments();
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchData();
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.required = required("Eingabe ist zwingend");
    }

    if (this.state.isSubmitting && prevProps.messages.isFetching && !this.props.messages.isFetching) {
      this.setState({
        isSubmitting: false,
        isSubmittingDone: true,
      });
      this.fetchComments();
      this.props.getMessages();
    }
    if (this.state.isLiking && prevProps.messages.isFetching && !this.props.messages.isFetching) {
      this.props.getMessages();
      this.setState({isLiking: false})
    }
  }

handleChange = (value) => {
    this.setState({
      comment: value,
      isValid: value.trim().length > 0,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { router } = this.props;
    const { params } = router;
    const { comment } = this.state;

    if (comment.trim().length === 0) {
      this.setState({ isValid: false });
      return;
    }

    this.setState({ isSubmitting: true });

    this.props.createComment({
      messageId: params.messageId,
      text: comment,
    });
  }

  focusCommentField = () => {
    // if (this.commentRef.current) {
    //   this.commentRef.current.focus();
    // }
  }

  likeMessage = () => {
    const { router, messages } = this.props;
    const { params } = router;

    this.setState({isLiking: true})
    const message = messages.messages.find(message => message.id === params.messageId);
    this.props.likeMessage({messageId: params.messageId, isLike: !message.self_liked})
  }

  render() {
    const { messages, router, handleSubmit, valid } = this.props;
    const { params } = router;
    const { comment, isValid, isSubmitting, isSubmittingDone, isLiking } = this.state;

    if (!messages.messages.length) {
      return <Loader />;
    }
    const message = messages.messages.find(message => message.id === parseInt(params.messageId, 10));
    if (!message) {
      return <Loader />;
    }

    const profilePic = message.user_image || ic_user;

    return (
      <div className="message-page">
        <form className="messageForm" onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col md={12} className="user-info">
                <div className={classnames("user-img", { 'has-profile-image': message.user_image })}>
                  <div style={({backgroundImage: `url(${profilePic})`})} />
                </div>
                <div className="user-details">
                  <span className="user-name">
                    {message.user_name}
                  </span>
                  <span className="message-date">
                    {moment(message.created_at).format('HH:mm, DD.MM.YYYY')}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="message-text">
                {message.text.split('\n').map((item, key) =>
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="message-attachments">
                <Gallery documents={message.documents} hasPreview />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="message-social">
                <Button className="message-likes" onClick={this.likeMessage} disabled={isSubmitting || isLiking}>
                  <img src={message.self_liked ? ic_thumpsup : ic_thumpsupfilled} alt="" />
                  {document.body.clientWidth > 500 ? `${message.like_count} Likes` : message.like_count}
                </Button>
                <Button className="message-comments" onClick={this.focusCommentField}>
                  <img src={message.self_commented ? ic_comment : ic_commentfilled} alt="" />
                  {document.body.clientWidth > 500 ? `${message.comment_count} Kommentare` : message.comment_count}
                </Button>
              </Col>
            </Row>

            {isSubmittingDone
              ?
              <Row>
                <Col md={12} className="comment-created">
                  Vielen Dank für Ihren Kommentar. Belano wird diesen prüfen und freigeben, womit er für alle Benutzer ersichtlich wird.
                </Col>
              </Row>
              :
              <>
                <Row id="newComment">
                  <Col md={12}>
                  <Textarea
                    ref={this.commentRef}
                    value={comment}
                    onChange={this.handleChange}
                    placeholderText="Kommentar verfassen…"
                  />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button type="submit" disabled={!this.state.isValid || this.state.isSubmitting}>
                      Kommentieren
                    </Button>
                  </Col>
                </Row>
              </>
            }

            <Row>
              <Col md={12} className="message-attachments">
                <ul>
                  {messages.comments[params.messageId]
                    ?
                    messages.comments[params.messageId].map((comment, idx) =>
                      <li key={comment.id}>
                        <Comment comment={comment} />
                      </li>
                    )
                    : <Loader />
                  }
                </ul>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    );
  }
}

BlackboardPage.propTypes = {
}

BlackboardPage.defaultProps = {
}

const mapStateToProps = state => ({
  messages: state.messages,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getMessages,
    getComments,
    createComment,
    likeMessage,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlackboardPage));
