import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Container, Row, Col, Button } from 'react-bootstrap';

import { Loader } from '../../components';
import { getLocation } from "../../store/actions/location_actions";
import { getServices } from "../../store/actions/services_actions";

import './ReceptionPage.scss';
import withRouter from "../../wrapper/withRouter";


class ReceptionPage extends Component {
  state = {
  }

  fetchData = () => {
    const { services, loc } = this.props;
    if (!services.isFetching && navigator.onLine) {
      this.props.getServices();
    }
    if (!loc.isFetching && navigator.onLine) {
      this.props.getLocation();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  goToProvider = () => {
    const { services, router } = this.props;
    const { navigate } = router;
    const defaultService = services.services[0];
    navigate(`/services/${defaultService.id}`);
  }

  render() {
    const { loc, services } = this.props;

    if (!loc.location) {
      return <Loader />;
    }

    return (
      <div className="reception-info">
        <Container>
          <Row>
            <Col md={12}>
              <ul>
                <li>
                  <p className="text">
                    {loc.location.text.split('\n').map((item, key) =>
                      <span key={key}>
                        {item}
                        <br/>
                      </span>
                    )}
                  </p>
                  {loc.location.hours &&
                  <>
                    <b>
                      Öffnungszeiten
                    </b>
                    <p className="hours">
                      {loc.location.hours.split('\n').map((item, key) =>
                        <span key={key}>
                          {item}
                          <br/>
                        </span>
                      )}
                    </p>
                  </>}
                  {(loc.location.phone || loc.location.email) &&
                  (<>
                    <b>
                      Kontakt
                    </b>
                    {loc.location.phone && <p className='phone'>
                      Telefon:&nbsp;
                      <a href={`tel:${loc.location.phone}`} className="phone">{loc.location.phone}</a>
                      </p>}
                  </>)}
                  <br/>
                  {loc.location.free_text && <p className='free-text'>{loc.location.free_text}</p>}
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button onClick={this.goToProvider} disabled={!services.services.length}>
                Rezeption kontaktieren
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ReceptionPage.propTypes = {
}

ReceptionPage.defaultProps = {
}

const mapStateToProps = state => ({
  loc: state.location,
  services: state.services,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getLocation,
    getServices,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReceptionPage));
