import { GET_PHONES } from '../actions/phones_actions';

const initialState = {
  phones: [],
  isFetching: false,
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PHONES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PHONES.SUCCESS:
      return {
        ...state,
        phones: action.data.results,
        isFetching: false,
      };
    case GET_PHONES.FAILURE:
      return {
        ...state,
        phones: [],
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
