import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { changePassword } from '../../store/actions/user_actions';
import { required } from '../../components/validators';
import { Input } from '../../components';
import withRouter from "../../wrapper/withRouter";
import './ChangePasswordPage.scss';

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      old_password: '',
      new_password: '',
      repeat_password: '',
      isFetching: false,
      isValid: false,
      passwordError: '',
      repeatPasswordError: '',
      nonFieldError: '',
    };

    this.required = required("Eingabe ist zwingend");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
        passwordError: '',
        repeatPasswordError: '',
        nonFieldError: '', // Clear errors on input change
      },
      () => {
        this.setState((prevState) => ({
          isValid:
            prevState.old_password.trim().length > 0 &&
            prevState.new_password.trim().length > 0 &&
            prevState.repeat_password.trim().length > 0,
        }));
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { changePassword } = this.props;
    const { old_password, new_password, repeat_password } = this.state;

    if (!this.state.isValid) {
      return;
    }

    this.setState({ isFetching: true });
    changePassword({ old_password, new_password, repeat_password });
  };

  componentDidUpdate(prevProps) {
    const { router, user } = this.props;
    const { navigate } = router;

    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.required = required("Eingabe ist zwingend");
    }

    const doneFetching = prevProps.user.isFetching && !user.isFetching;
    if (this.state.isFetching && doneFetching) {
      this.setState({ isFetching: false });
      if (!user.error) {
        setTimeout(() => navigate(`/profile/success`), 1000);
      } else {
        let passwordError = '';
        let repeatPasswordError = '';
        let nonFieldError = '';

        if (user.error.old_password && user.error.old_password[0] === 'Wrong password') {
          passwordError = "Das eingegebene Passwort ist falsch, bitte überprüfen Sie die Schreibweise.";
        }

        if (user.error.non_field_errors && user.error.non_field_errors[0] === "Passwords do not match") {
          repeatPasswordError = "Die Passwörter stimmen nicht überein.";
        } else if (user.error.non_field_errors) {
          nonFieldError = user.error.non_field_errors[0] || '';
        }

        this.setState({ passwordError, repeatPasswordError, nonFieldError });
      }
    }
  }

  render() {
    const { old_password, new_password, repeat_password, isValid, isFetching, passwordError, repeatPasswordError, nonFieldError } = this.state;

    return (
      <div className="changePasswordPage">
        <form className="changePasswordForm" onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col md={12}>
                <Input
                  name="old_password"
                  type="password"
                  value={old_password}
                  onChange={this.handleChange}
                  placeholder="Aktuelles Passwort"
                  validate={this.required}
                  error={passwordError}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Input
                  name="new_password"
                  type="password"
                  value={new_password}
                  onChange={this.handleChange}
                  placeholder="Neues Passwort"
                  validate={this.required}
                  error={repeatPasswordError}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Input
                  name="repeat_password"
                  type="password"
                  value={repeat_password}
                  onChange={this.handleChange}
                  placeholder="Neues Passwort bestätigen"
                  validate={this.required}
                  error={repeatPasswordError}
                />
              </Col>
            </Row>
            {nonFieldError && (
              <Row>
                <Col md={12}>
                  <div className="error-message">{nonFieldError}</div>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12}>
                <Button type="submit" disabled={!isValid || isFetching}>
                  Neues Passwort speichern
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    );
  }
}

ChangePasswordPage.propTypes = {
  // actions
  changePassword: PropTypes.func.isRequired,
};

ChangePasswordPage.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePasswordPage));
