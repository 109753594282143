import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';

import { getPhones } from '../api/phones';

import {
  GET_PHONES,
  getPhonesSuccess,
  getPhonesFailure,
} from '../actions/phones_actions';

function* getPhonesSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getPhones);
    yield put(getPhonesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getPhonesFailure(processedError || error));
  }
}

const getPhonesWatcher = function* getPhonesWatcher() {
  yield takeLatest(GET_PHONES.REQUEST, getPhonesSaga);
};

export default function* authSaga() {
  yield all([
    fork(getPhonesWatcher),
  ]);
}
