import React, { Component } from 'react';
import classnames from 'classnames';
import './styles.scss';

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSpellcheck: props.isSpellcheck || false,
      isFocused: false,
    };
  }

  handleFocus = () => {
    const { value, emptyValue, noEmptyValue, onChange } = this.props;
    if (!noEmptyValue && emptyValue && !value) {
      onChange(emptyValue);
    }
    this.setState({ isFocused: true });
  }

  handleBlur = () => {
    const { value, emptyValue, noEmptyValue, onChange } = this.props;
    if (!noEmptyValue && emptyValue && value === emptyValue) {
      onChange('');
    }
    this.setState({ isFocused: false });
  }

  render() {
    const { isSpellcheck, value, onChange, placeholderText, noEmptyValue } = this.props;
    return (
      <div className="input-textarea">
        <textarea
          name="comment"
          spellCheck={isSpellcheck}
          value={value}
          onChange={event => onChange(event.target.value)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
        <div
          className={classnames({
            'placeholder': true,
            'hidden': value || (noEmptyValue && this.state.isFocused),
            'custom-placeholder': true,
          })}
        >
          {placeholderText}
        </div>
      </div>
    );
  }
}

export default Textarea;
