import { all, fork, takeLatest } from 'redux-saga/effects';
import ReactGA from 'react-ga4';

import { LOGIN, LOGOUT, FORGOT_PASSWORD } from "../actions/auth_actions";
import { CHANGE_PASSWORD, GET_PROFILE, UPDATE_PROFILE } from "../actions/user_actions";
import {
  CREATE_MESSAGE,
  CREATE_COMMENT,
  CREATE_MESSAGE_DOCUMENT,
  LIKE_MESSAGE,
} from "../actions/messages_actions";
import { CREATE_PROVIDER_REQUEST } from "../actions/services_actions";

// eslint-disable-next-line
function* loginSuccessSaga(action) {
  try { ReactGA.event({
    category: 'User',
    action: 'Logged in'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* logoutSuccessSaga(action) {
  try {
    ReactGA.set({ userId: null });
    ReactGA.event({
      category: 'User',
      action: 'Logged out'
    })
  } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* forgotPasswordSuccessSaga(action) {
  try { ReactGA.event({
    category: 'User',
    action: 'Forgot password'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* changePasswordSuccessSaga(action) {
  try { ReactGA.event({
    category: 'User',
    action: 'Changed password'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* getProfileSuccessSaga(action) {
  try {
    ReactGA.set({ userId: action.data.id });
  } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* updateProfileSuccessSaga(action) {
  try { ReactGA.event({
    category: 'User',
    action: 'Updated profile'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* createMessageSuccessSaga(action) {
  try { ReactGA.event({
    category: 'Message',
    action: 'Posted message'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* createCommentSuccessSaga(action) {
  try { ReactGA.event({
    category: 'Message',
    action: 'Posted comment'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* createMessageDocumentSuccessSaga(action) {
  try { ReactGA.event({
    category: 'Message',
    action: 'Attached document'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* likeMessageSuccessSaga(action) {
  try { ReactGA.event({
    category: 'Message',
    action: 'Liked or unliked message'
  }) } catch (error) { console.warn("GA error") }
}
// eslint-disable-next-line
function* createProviderRequestSuccessSaga(action) {
  try { ReactGA.event({
    category: 'Provider',
    action: 'Created request'
  }) } catch (error) { console.warn("GA error") }
}

const loginSuccessWatcher = function* loginSuccessWatcher() { yield takeLatest(LOGIN.SUCCESS, loginSuccessSaga) };
const logoutSuccessWatcher = function* logoutSuccessWatcher() { yield takeLatest(LOGOUT.SUCCESS, logoutSuccessSaga) };
const forgotPasswordSuccessWatcher = function* forgotPasswordSuccessWatcher() { yield takeLatest(FORGOT_PASSWORD.SUCCESS, forgotPasswordSuccessSaga) };
const changePasswordSuccessWatcher = function* changePasswordSuccessWatcher() { yield takeLatest(CHANGE_PASSWORD.SUCCESS, changePasswordSuccessSaga) };
const getProfileSuccessWatcher = function* getProfileSuccessWatcher() { yield takeLatest(GET_PROFILE.SUCCESS, getProfileSuccessSaga) };
const updateProfileSuccessWatcher = function* updateProfileSuccessWatcher() { yield takeLatest(UPDATE_PROFILE.SUCCESS, updateProfileSuccessSaga) };
const createMessageSuccessWatcher = function* createMessageSuccessWatcher() { yield takeLatest(CREATE_MESSAGE.SUCCESS, createMessageSuccessSaga) };
const createCommentSuccessWatcher = function* createCommentSuccessWatcher() { yield takeLatest(CREATE_COMMENT.SUCCESS, createCommentSuccessSaga) };
const createMessageDocumentSuccessWatcher = function* createMessageDocumentSuccessWatcher() { yield takeLatest(CREATE_MESSAGE_DOCUMENT.SUCCESS, createMessageDocumentSuccessSaga) };
const likeMessageSuccessWatcher = function* likeMessageSuccessWatcher() { yield takeLatest(LIKE_MESSAGE.SUCCESS, likeMessageSuccessSaga) };
const createProviderRequestSuccessWatcher = function* createProviderRequestSuccessWatcher() { yield takeLatest(CREATE_PROVIDER_REQUEST.SUCCESS, createProviderRequestSuccessSaga) };

export default function* authSaga() {
  yield all([
    fork(loginSuccessWatcher),
    fork(logoutSuccessWatcher),
    fork(forgotPasswordSuccessWatcher),
    fork(changePasswordSuccessWatcher),
    fork(getProfileSuccessWatcher),
    fork(updateProfileSuccessWatcher),
    fork(createMessageSuccessWatcher),
    fork(createCommentSuccessWatcher),
    fork(createMessageDocumentSuccessWatcher),
    fork(likeMessageSuccessWatcher),
    fork(createProviderRequestSuccessWatcher),
  ]);
}
