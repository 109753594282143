import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import rootReducer from './reducers';

export const history = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history,
  routerReducerKey: 'router',  // Keep the same key as before
  // other options if needed
});

export const { store, runSaga, persistor } = (function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();

  const combinedReducers = combineReducers({
    ...rootReducer,
    router: routerReducer,
  });

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const store = createStore(
    combinedReducers,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware,
        sagaMiddleware
      ),
    ),
  );

  const persistor = persistStore(store);

  return {
    store,
    persistor,
    runSaga: sagaMiddleware.run,
  };
})();

export const reduxHistory = createReduxHistory(store);
