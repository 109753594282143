import { LOGIN, LOGOUT, FORGOT_PASSWORD } from '../actions/auth_actions';

const initialState = {
  token: null,
  authenticated: false,
  isFetching: false,
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN.REQUEST:
    case LOGOUT.REQUEST:
    case FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        authenticated: false,
        isFetching: true,
      };
    case LOGIN.SUCCESS:
      return {
        ...state,
        ...action.data.token,
        authenticated: true,
        isFetching: false,
      };
    case LOGIN.FAILURE:
    case LOGOUT.SUCCESS:
    case LOGOUT.FAILURE:
    case FORGOT_PASSWORD.SUCCESS:
    case FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        token: null,
        authenticated: false,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
