import createRequestTypes from '../../components/requestsTypes';

export const GET_SERVICES = createRequestTypes('GET_SERVICES');
export const GET_PROVIDERS = createRequestTypes('GET_PROVIDERS');
export const CREATE_PROVIDER_REQUEST = createRequestTypes('CREATE_PROVIDER_REQUEST');

export function getServices() {
  return {
    type: GET_SERVICES.REQUEST,
  }
}
export function getServicesSuccess(data) {
  return {
    type: GET_SERVICES.SUCCESS,
    data
  }
}
export function getServicesFailure(error) {
  return {
    type: GET_SERVICES.FAILURE,
    error,
  }
}

export function getProviders(serviceId) {
  return {
    type: GET_PROVIDERS.REQUEST,
    serviceId,
  }
}
export function getProvidersSuccess(data) {
  return {
    type: GET_PROVIDERS.SUCCESS,
    data
  }
}
export function getProvidersFailure(error) {
  return {
    type: GET_PROVIDERS.FAILURE,
    error,
  }
}

export function createProviderRequest(providerRequest) {
  return {
    type: CREATE_PROVIDER_REQUEST.REQUEST,
    providerRequest,
  }
}
export function createProviderRequestSuccess(data) {
  return {
    type: CREATE_PROVIDER_REQUEST.SUCCESS,
    data
  }
}
export function createProviderRequestFailure(error) {
  return {
    type: CREATE_PROVIDER_REQUEST.FAILURE,
    error,
  }
}
