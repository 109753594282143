import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IdleTimerProvider } from 'react-idle-timer';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Header } from './components';
import { RESET_TIMEOUT, GA_KEY } from '../constants';
import AuthService from '../services/auth.service';
import { getProfile } from "../store/actions/user_actions";
import { getDocuments } from "../store/actions/documents_actions";
import { getPhones } from "../store/actions/phones_actions";
import { getServices } from "../store/actions/services_actions";
import { getLocation } from "../store/actions/location_actions";
import { getMessages } from "../store/actions/messages_actions";
import withRouter from "../wrapper/withRouter";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.onIdle = this.onIdle.bind(this);
  }

  componentDidMount() {
    const { user, documents, phones, services, loc, messages, router } = this.props;
    const { location, navigate } = router;

    ReactGA.initialize(GA_KEY);

    if (AuthService.token) {
      if (!user.user && !user.isFetching) {
        this.props.getProfile();
      }
      if (!documents.documents.length && !documents.isFetching) {
        this.props.getDocuments();
      }
      if (!phones.phones.length && !phones.isFetching) {
        this.props.getPhones();
      }
      if (!services.services.length && !services.isFetching) {
        this.props.getServices();
      }
      if (!loc.location && !loc.isFetching) {
        this.props.getLocation();
      }
      if (!messages.messages.length && !messages.isFetching) {
        this.props.getMessages();
      }
    } else {
      if (!this.isForgotPasswordPath(location.pathname)) {
        navigate('/login');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    const { location, navigate } = router;

    if (prevProps.router.location.pathname !== location.pathname) {
      if (!AuthService.token && !this.isForgotPasswordPath(location.pathname)) {
        navigate(`/login`);
      }
    }
  }

  isForgotPasswordPath(pathname) {
    return pathname.includes('forgot-password');
  }

  onIdle() {
    const { navigate } = this.props.router;
    if (AuthService.token && AuthService.isAuthenticated) {
      navigate(`/`);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className="page-wrapper">
        <IdleTimerProvider
          timeout={RESET_TIMEOUT}
          onIdle={this.onIdle}
          debounce={250}
        />
        <Header />
        <ToastContainer
          className='toast-container'
          toastClassName="dark-toast"
        />
        {children}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  documents: state.documents,
  phones: state.phones,
  services: state.services,
  loc: state.location,
  messages: state.messages,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getProfile,
    getDocuments,
    getPhones,
    getServices,
    getLocation,
    getMessages,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
