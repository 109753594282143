import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import { Container, Row, Col } from 'react-bootstrap';

import {getDocuments} from "../../store/actions/documents_actions";

import './ApartmentPage.scss';
import withRouter from "../../wrapper/withRouter";

class Document extends Component {
  constructor(props) {
    super(props);

    this.spanRef = React.createRef();
    this.divRef = React.createRef();
  }

  componentDidMount() {
    const maxHeight = this.divRef.current.clientHeight;
    const currentHeight = this.spanRef.current.clientHeight;

    if (currentHeight > maxHeight) {
      this.spanRef.current.className += ' long';
    }
  }

  openDocument(url) {
    window.open(url, '_blank');
  }

  render() {
    const {document} = this.props;
    return (
      <li
        key={document.id}
        ref={this.divRef}
        onClick={() => this.openDocument(document.document)}
      >
        <img src={document.icon} alt="" />
        <span ref={this.spanRef}>{document.title}</span>
      </li>
    )
  }
}

class ApartmentPage extends Component {
  state = {
  }

  componentDidMount() {
    const { documents } = this.props;
    if (!documents.isFetching && navigator.onLine) {
      this.props.getDocuments();
    }
  }

  render() {
    const { documents } = this.props;
    return (
      <div className="apartment">
        <Container>
          <Row>
            <Col md={12} className="text">
              Klicken Sie auf die gewünschte Anleitung und die Broschüre wird geöffnet.
            </Col>
          </Row>
          <Row>
            <ul>
            {documents.documents.map((document, idx) =>
              <Document key={document.id} document={document} />
            )}
            </ul>
          </Row>
        </Container>
      </div>
    );
  }
}

ApartmentPage.propTypes = {
}

ApartmentPage.defaultProps = {
}

const mapStateToProps = state => ({
  documents: state.documents,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getDocuments,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApartmentPage));
