import createRequestTypes from '../../components/requestsTypes';

export const LOGIN = createRequestTypes('LOGIN');
export const LOGOUT = createRequestTypes('LOGOUT');
export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD');

export function login(apartment_number, password) {
  return {
    type: LOGIN.REQUEST,
    credentials: {
      apartment_number,
      password,
    }
  }
}
export function loginSuccess(data) {
  return {
    type: LOGIN.SUCCESS,
    data
  }
}
export function loginFailure(error) {
  return {
    type: LOGIN.FAILURE,
    error,
  }
}

export function logout() {
  return {
    type: LOGOUT.REQUEST,
  }
}
export function logoutSuccess() {
  return {
    type: LOGOUT.SUCCESS,
  }
}
export function logoutFailure(error) {
  return {
    type: LOGOUT.FAILURE,
    error,
  }
}

export function forgotPassword(apartment_number) {
  return {
    type: FORGOT_PASSWORD.REQUEST,
    credentials: {
      apartment_number,
    }
  }
}
export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD.SUCCESS,
    data
  }
}
export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD.FAILURE,
    error,
  }
}
