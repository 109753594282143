import {API_URL} from '../../constants';
import http from './http-decorator';
import AuthService from '../../services/auth.service';


export const getServices = () => http(fetch(
  `${API_URL}/api/services/`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const getProviders = (serviceId) => http(fetch(
  `${API_URL}/api/providers/?service_id=${serviceId}`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const createProviderRequest = (providerId, text) => http(fetch(
  `${API_URL}/api/providers/${providerId}/request/`,
  {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
    body: JSON.stringify({ text }),
  },
));
