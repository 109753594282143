import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import ic_close from "../../assets/icons/ic_close.svg";
import ic_galleryarrowleft from "../../assets/icons/ic_galleryarrowleft.svg";

import './styles.scss';

class Gallery extends Component {
  state = {
    show: false,
    selectedDoc: 0,
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  openDocument(url) {
    window.open(url, '_blank');
  }

  handlePrev = () => {
    const {documents} = this.props;
    const {selectedDoc} = this.state;
    const prevDoc = selectedDoc > 0 ? selectedDoc - 1 : documents.length - 1;
    this.setState({ selectedDoc: prevDoc });
  }

  handleNext = () => {
    const {documents} = this.props;
    const {selectedDoc} = this.state;
    const nextDoc = selectedDoc < documents.length - 1 ? selectedDoc + 1 : 0;
    this.setState({ selectedDoc: nextDoc });
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = (docIdx) => {
    const {documents} = this.props;
    if (this.props.hasPreview) {
      if (document.body.clientWidth > 500) {
        this.setState({ show: true, selectedDoc: docIdx });
      }
      else {
        this.openDocument(documents[docIdx].document)
      }
    }
  }

  handleKeyPress = (event) => {
    if (this.state.show) {
      switch (event.key) {
        case 'ArrowLeft': {
          this.handlePrev();
          break;
        }
        case 'ArrowRight': {
          this.handleNext();
          break;
        }
        case 'Escape': {
          this.handleClose();
          break;
        }
        default:
      }
    }
  }

  isImg(doc) {
    return doc.document.match(/\.(png|jpg|jpeg|bmp|gif|tiff|psd|raw|webp|svg)$/)
  }

  render() {
    const { documents } = this.props;
    const selectedDocument = documents[this.state.selectedDoc];

    return (
      <div className="gallery-cmp">
        {documents.map( (doc, idx) => {
          return this.isImg(doc)
            ?
            <div key={idx} className="img-preview" onClick={this.handleShow.bind(this, idx)}>
              <img src={doc.document} alt=""/>
            </div>
            :
            <div key={idx} className="document-preview" onClick={this.handleShow.bind(this, idx)}>
              <span>{doc.name}</span>
            </div>
        })}
        <Modal show={this.state.show} className="gallery-cmp">
          <Modal.Header>
            <Button onClick={this.handleClose}>
              <img src={ic_close} alt=""/>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Button onClick={this.handlePrev}>
              <img src={ic_galleryarrowleft} alt=""/>
            </Button>
            {selectedDocument &&
            <div className="preview">
              {this.isImg(selectedDocument)
                ?
                <div className="img-preview">
                  <img src={selectedDocument.document} alt=""/>
                </div>
                :
                <div className="document-preview overlay">
                  <a href={selectedDocument.document} target="_blank" rel="noopener noreferrer">{selectedDocument.name}</a>
                </div>
              }
            </div>
            }
            <Button onClick={this.handleNext}>
              <img src={ic_galleryarrowleft} alt=""/>
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Gallery;
