export default class AuthService {
  static get isAuthenticated() {
    return !!AuthService.token;
  }

  static get token() {
    return localStorage.getItem('token');
  }

  static save({ token }) {
    localStorage.setItem('token', token);
  }

  static clear() {
    localStorage.clear();
  }
}
