import { all, fork, call, delay, put, takeLatest } from 'redux-saga/effects';

import AuthService from '../../services/auth.service';

import { login, logout, getTenant, forgotPassword, setFCM, removeFCM } from '../api/auth';
import { getProfile } from '../api/user';
import { getDocuments } from '../api/documents';
import { getPhones } from '../api/phones';
import { getServices } from '../api/services';
import { getLocation } from '../api/location';
import { getMessages } from '../api/messages';

import {
  LOGIN,
  loginSuccess,
  loginFailure,
  LOGOUT,
  logoutSuccess,
  logoutFailure,
  FORGOT_PASSWORD,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from '../actions/auth_actions';
import {
  getProfileSuccess,
} from '../actions/user_actions';
import {
  getDocumentsSuccess,
} from '../actions/documents_actions';
import {
  getPhonesSuccess,
} from '../actions/phones_actions';
import {
  getServicesSuccess,
} from '../actions/services_actions';
import {
  getLocationSuccess,
} from '../actions/location_actions';
import {
  getMessagesSuccess,
} from '../actions/messages_actions';

function* loginSaga(action) {
  yield delay( 500);
  try {
    if (!AuthService.token) {
      const { data: token } = yield call(login, action.credentials.apartment_number, action.credentials.password);
      AuthService.save(token);
    }
    yield put(loginSuccess(AuthService.token));
    const [profile, documents, phones, services, location, messages] = yield all([
      call(getProfile),
      call(getDocuments),
      call(getPhones),
      call(getServices),
      call(getLocation),
      call(getMessages),
    ]);
    const fcmToken = localStorage.getItem('FCM_TOKEN');
    if (fcmToken) {
      try { yield call(setFCM, fcmToken) } catch (e) {}
    }
    yield put(getProfileSuccess(profile.data));
    yield put(getDocumentsSuccess(documents.data));
    yield put(getPhonesSuccess(phones.data));
    yield put(getServicesSuccess(services.data));
    yield put(getLocationSuccess(location.data));
    yield put(getMessagesSuccess(messages.data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(loginFailure(processedError || error));
  }
}

function* logoutSaga(action) {
  yield delay( 500);
  try {
    const fcmToken = localStorage.getItem('FCM_TOKEN');
    if (fcmToken) {
      try { yield call(removeFCM, fcmToken) } catch (e) {}
    }
    const { data } = yield call(logout);
    AuthService.clear();
    yield put(logoutSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(logoutFailure(processedError || error));
  }
}

function* forgotPasswordSaga(action) {
  yield delay( 500);
  try {
    const tenant = yield call(getTenant, action.credentials.apartment_number);
    const { data } = yield call(forgotPassword, { email: tenant.data.email });
    yield put(forgotPasswordSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(forgotPasswordFailure(processedError || error));
  }
}

const loginWatcher = function* loginWatcher() {
  yield takeLatest(LOGIN.REQUEST, loginSaga);
};

const logoutWatcher = function* logoutWatcher() {
  yield takeLatest(LOGOUT.REQUEST, logoutSaga);
};

const forgotPasswordWatcher = function* forgotPasswordWatcher() {
  yield takeLatest(FORGOT_PASSWORD.REQUEST, forgotPasswordSaga);
};

export default function* authSaga() {
  yield all([
    fork(loginWatcher),
    fork(logoutWatcher),
    fork(forgotPasswordWatcher),
  ]);
}
