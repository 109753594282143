import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import { Container, Row, Col } from 'react-bootstrap';
import classnames from "classnames";

import {getPhones} from "../../store/actions/phones_actions";

import './PhoneNumbersPage.scss';
import {getLocation} from "../../store/actions/location_actions";
import {getProfile} from "../../store/actions/user_actions";
import withRouter from "../../wrapper/withRouter";

class Phone extends Component {
  constructor(props) {
    super(props);

    this.spanRef = React.createRef();
    this.divRef = React.createRef();
  }

  componentDidMount() {
    const maxHeight = this.divRef.current.clientHeight;
    const currentHeight = this.spanRef.current.clientHeight;

    if (currentHeight > maxHeight) {
      this.spanRef.current.className += ' long';
    }
  }

  render() {
    const {phone} = this.props;
    const isPhoneNumHidden = phone.hidden;
    return (
      <a href={`tel:${phone.number}`} key={phone.id} ref={this.divRef}>
        <li className={classnames({
          'highlighted': phone.highlighted,
        })}>
          <img src={phone.icon}  alt="" />
          {isPhoneNumHidden ? (
            <span ref={this.spanRef}>{phone.name}</span>
          ) : (
            <span ref={this.spanRef}>{phone.name}: <span className="phone-number">{phone.number}</span></span>
          )}
        </li>
      </a>
    )
  }
}

class PhoneNumbersPage extends Component {
  state = {
  }

  componentDidMount() {
    const { phones, loc, user } = this.props;

    if (!phones.isFetching && navigator.onLine) {
      this.props.getPhones();
    }

    if (!loc.isFetching && navigator.onLine) {
      this.props.getLocation();
    }

    if (!user.isFetching && navigator.onLine) {
      this.props.getProfile();
    }
  }

  render() {
    const { phones, loc, user } = this.props;
    let emergencyServicesDeactivated = true;

    if (user.user) {
      emergencyServicesDeactivated = user.user.emergency_services_deactivated;
    }

    return (
      <div className="phoneNumbers">
        <Container>
          <Row>
            <Col md={12} className="text">
              Klicken Sie auf die gewünschte Telefonnummer, wobei Sie mit der gewählten Nummer verbunden werden.
            </Col>
          </Row>
          <Row>
            <ul>
            {phones.phones.filter(function(phone) {
                return !(emergencyServicesDeactivated &&
                  phone.number.replace(/ /g, '') === loc.location.direct_number.replace(/ /g, ''));
              }).map((phone, idx) =>
                <Phone phone={phone} key={idx}/>
            )}
            </ul>
          </Row>
        </Container>
      </div>
    );
  }
}

PhoneNumbersPage.propTypes = {
}

PhoneNumbersPage.defaultProps = {
}

const mapStateToProps = state => ({
  phones: state.phones,
  loc: state.location,
  user: state.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getPhones,
    getLocation,
    getProfile,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PhoneNumbersPage));
