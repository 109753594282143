import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import { Container, Row, Col, Button } from 'react-bootstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import ic_camera from "../../assets/icons/ic_camera.svg";
import ic_file from "../../assets/icons/ic_file.svg";
import ic_close from "../../assets/icons/ic_close.svg";
import { Textarea } from '../../components';
import { getMessages, createMessageDocument, createMessage } from "../../store/actions/messages_actions";
import { required } from "../../components/validators";
import {FILE_SIZE_LIMIT} from '../../constants'

import './BlackboardForm.scss';
import withRouter from "../../wrapper/withRouter";

const isImg = (filename) => {
  return filename.match(/\.(png|jpg|jpeg|bmp|gif|tiff|psd|raw|webp|svg)$/)
}

class BlackboardForm extends Component {
  state = {
    photos: [],
    docs: [],
    isSubmitting: false,
    isSubmittingFiles: false,
    isSubmittingDone: false,
  }

  constructor(props) {
    super(props);

    this.required = required("Eingabe ist zwingend");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.required = required("Eingabe ist zwingend");
    }
    if (this.state.isSubmitting && !this.state.isSubmittingFiles && prevProps.messages.isFetching && !this.props.messages.isFetching) {
      this.setState({isSubmittingFiles: true});
      const files = [...this.state.photos, ...this.state.docs];
      this.props.createMessageDocument({
        messageId: this.props.messages.selectedMessage,
        files
      });
    }
    if (this.state.isSubmitting && this.state.isSubmittingFiles && prevProps.messages.isFetching && !this.props.messages.isFetching) {
      this.setState({
        isSubmittingFiles: false,
        isSubmitting: false,
        isSubmittingDone: true,
      });
    }
  }

  handleChange = (value) => {
    this.setState({
      message: value,
      isValid: value.trim().length > 0,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.isValid) {
      return;
    }
    this.setState({isSubmitting: true});
    this.props.createMessage({
      text: this.state.message,
    });
  };

  backToBlackboard = () => {
    const { router } = this.props;
    const { navigate } = router;
    navigate(`/blackboard`)
  }

  removeAttachment = (idx) => {
    const { photos, docs } = this.state;
    if (photos.length && idx <= photos.length - 1) {
      this.setState({
        photos: photos.slice(0, idx).concat(photos.slice(idx + 1))
      })
    } else {
      this.setState({
        docs: docs.slice(0, idx - photos.length).concat(docs.slice(idx - photos.length + 1))
      })
    }
  }

  changeAttachment = (e) => {
    const isDoc = e.target.id === 'upload-doc';
    const files = []
      .slice.call( e.target.files, 0)
      .filter(file => {
        if (file.size <= FILE_SIZE_LIMIT) {
          return true;
        } else {
          toast("Die Datei ${name} ist zu gross");
          return false;
        }
      })
      .slice(0, isDoc ? 3 : 2);
    if (isDoc) {
      this.setState({docs: files})
    } else {
      this.setState({photos: files})
    }
  }

  render() {
    const { message, isValid, isSubmitting, isSubmittingDone, photos, docs } = this.state;

    return (
      <div className="blackboard-form">
        <form className="providerForm" onSubmit={this.handleSubmit}>
          <Container>
            {isSubmittingDone ? (
              <Row className="submitting-done">
                <Col md={12}>
                  Vielen Dank für Ihren Beitrag. Belano wird diesen prüfen und freigeben, womit er für alle Benutzer ersichtlich wird.
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col md={12} className="desc">
                    Verfassen Sie Ihren Beitrag in dem unten aufgeführten Feld und ergänzen Sie falls erwünscht ein Foto oder Dokument zum Beitrag.
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Textarea
                      name="message"
                      value={message}
                      onChange={this.handleChange}
                      placeholderText="Hier Beitragstext eingeben…"
                      noEmptyValue
                    />
                  </Col>
                </Row>
                <Row className="attachments-row">
                  <Col md={6}>
                    <Button>
                      <label htmlFor="upload-photo">
                        <img src={ic_camera} alt="" />
                        <span className="text">Fotos hinzufügen</span>
                        <input
                          type="file"
                          id="upload-photo"
                          onChange={this.changeAttachment}
                          accept="image/*"
                          multiple
                        />
                      </label>
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button>
                      <label htmlFor="upload-doc">
                        <img src={ic_file} alt="" />
                        <span className="text">Dokumente hinzufügen</span>
                        <input
                          type="file"
                          id="upload-doc"
                          onChange={this.changeAttachment}
                          accept=".pdf"
                          multiple
                        />
                      </label>
                    </Button>
                  </Col>
                </Row>
                <div
                  className={classnames({
                    'attachments-container': true,
                    'has-files': [...photos, ...docs].length,
                  })}
                >
                  {[...photos, ...docs].map((file, idx) => (
                    <Row className="attachments-list" key={idx}>
                      <Col md={12}>
                        <img src={isImg(file.name) ? ic_camera : ic_file} alt="" />
                        <span className="text">{file.name}</span>
                        <Button variant="link" onClick={() => this.removeAttachment(idx)}>
                          <img alt="" style={{ WebkitMaskImage: `url(${ic_close})` }} />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>
              </>
            )}
            <Row>
              <Col md={12}>
                {isSubmittingDone ? (
                  <Button onClick={this.backToBlackboard}>Zur Pinnwand</Button>
                ) : (
                  <Button className="btn-create" type="submit" disabled={!isValid || isSubmitting}>
                    Beitrag veröffentlichen
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    );
  }
}

BlackboardForm.propTypes = {
}

BlackboardForm.defaultProps = {
}

const mapStateToProps = state => ({
  messages: state.messages,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getMessages,
    createMessageDocument,
    createMessage,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlackboardForm));
