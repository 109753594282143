import React from "react";
import { Button } from "react-bootstrap";
import classnames from "classnames";
import { connect } from "react-redux";

import ic_arrowleft from "../../../assets/icons/ic_arrowleft.svg";
import ic_arrowleft_brown from "../../../assets/icons/ic_arrowleft_brown.svg";
import ic_user from "../../../assets/icons/ic_user.svg";
import logo from "../../../assets/logos/belano_logo_new.svg";
import AuthService from "../../../services/auth.service";

import "./styles.scss";
import withRouter from "../../../wrapper/withRouter";

const Header = ({ router, user, services, loc }) => {
  const { location, navigate, params } = router;
  const goBack = () => {
    const isProviderPage = location.pathname.match(/^\/services\/\d+/) !== null;
    const isMessagesPage = location.pathname.match(/^\/messages\/.+/) !== null;
    switch (location.pathname) {
      case "/forgot-password": {
        navigate(`/login`);
        break;
      }
      case "/change-password": {
        navigate(`/profile`);
        break;
      }
      case "/reception": {
        navigate(`/`);
        break;
      }
      case "/services/1":
      case "/services/3": {
        if (location.search === "?fd=1") {
          navigate("/services");
        } else {
          navigate("/");
        }
        break;
      }
      default: {
        if (isProviderPage) {
          navigate(`/services/`);
        } else if (isMessagesPage) {
          navigate(`/blackboard/`);
        } else {
          navigate(`/`);
        }
      }
    }
  };

  const goProfile = () => {
    navigate(`/profile`);
  };

  // function showLogo() {
    
  // }

  const providerPage = location.pathname.match(/^\/services\/(\d+)/);

  const isHomePage = location.pathname.match(/^\/$/);
  const isLoginPage = location.pathname.match(/^\/login$/);
  const isForgotPasswordPage = location.pathname.match(/^\/forgot-password/);
  const isProviderPage = providerPage !== null;
  const isBlackboardPage = location.pathname.match(/^\/blackboard\/*/) !== null;
  const isMessagesPage = location.pathname.match(/^\/messages\/.+/) !== null;
  const showLogo = AuthService.isAuthenticated;
  const showProfile = AuthService.isAuthenticated && isHomePage;
  const showLanguage = !AuthService.isAuthenticated || isHomePage || isLoginPage || isForgotPasswordPage;

  let title = "";
  switch (location.pathname.replace(/\/$/, "")) {
    case "/phone-numbers": {
      title = "Wichtige Telefonnummern";
      break;
    }
    case "/apartment": {
      title = "Meine Wohnung";
      break;
    }
    case "/blackboard": {
      title = "Pinnwand";
      break;
    }
    case "/services": {
      title = "Dienstleistungen";
      break;
    }
    case "/reception": {
      title = "Rezeption";
      break;
    }
    case "/profile": {
      title = "Mein Profil";
      break;
    }
    case "/change-password": {
      title = "Passwort ändern";
      break;
    }
    case "/messages/new": {
      title = "Neuer Beitrag";
      break;
    }
    default: {
      if (isMessagesPage) {
        title = "Pinnwand";
        break;
      }

      if (isHomePage && loc.location) {
        title = loc.location.title
        break;
      }

      if (isProviderPage) {
        const providerId = parseInt(providerPage[1]);
        const provider = services.services.find((service) => {
          return service.id === providerId;
        })

        title = provider.title
      }
    }
  }

  const profilePic = (user.user && user.user.profile_picture) || ic_user;

  return (
    <div
      className={classnames({
        header: true,
        "is-login": isLoginPage || isForgotPasswordPage,
      })}
    >
      <div className="content-wrapper">
        <div className="header-inner-wrapper">
        {showLogo && <img className={classnames({
          "logo-image": true,
        })} src={((loc.location && !isHomePage) && loc.location.logo) || logo} alt="logo" />}
          {!isHomePage && !isLoginPage && (
            <Button
              variant="link"
              className={classnames({
                "back-button": true,
              })}
              onClick={goBack}
            >
              <img className="ic_arrowleft" src={ic_arrowleft} alt="" />
              Zurück
            </Button>
          )}
          {title && <div className="title">{title}</div>}
          {showProfile && (
            <Button
              className={classnames({
                "profile-button": true,
                "has-profile-image": user.user && user.user.profile_picture,
              })}
              onClick={goProfile}
            >
              <div className={classnames({
                "ic_user": true,
                "no-profile-image": user.user && !user.user.profile_picture,
              })} style={({backgroundImage: `url(${profilePic})`})} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  services: state.services,
  loc: state.location,
});

export default connect(mapStateToProps)(withRouter(Header));
