import { API_URL } from '../../constants';
import http from './http-decorator';
import AuthService from "../../services/auth.service";


export const login = (payload) => http(fetch(
  `${API_URL}/api/login/`,
  {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...payload }),
  },
));

export const logout = () => http(fetch(
  `${API_URL}/api/logout/`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const getTenant = ({apartment_number}) => http(fetch(
  `${API_URL}/api/tenant/?apartment_number=${apartment_number}`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  },
));

export const forgotPassword = (payload) => http(fetch(
  `${API_URL}/api/password_reset/`,
  {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...payload }),
  },
));

export const forgotPasswordConfirm = (payload) => http(fetch(
  `${API_URL}/api/password_reset/confirm/`,
  {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...payload }),
  },
));

export const setFCM = (fcmToken) => http(fetch(
  `${API_URL}/api/fcm/token/`,
  {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
    body: JSON.stringify({ type: 'web', registration_id: fcmToken }),
  },
));

export const removeFCM = (fcmToken) => http(fetch(
  `${API_URL}/api/fcm/token/${fcmToken}/`,
  {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));
