import createRequestTypes from '../../components/requestsTypes';

export const GET_PROFILE = createRequestTypes('GET_PROFILE');
export const UPDATE_PROFILE = createRequestTypes('UPDATE_PROFILE');
export const CHANGE_PASSWORD = createRequestTypes('CHANGE_PASSWORD');

export function getProfile() {
  return {
    type: GET_PROFILE.REQUEST,
  }
}
export function getProfileSuccess(data) {
  return {
    type: GET_PROFILE.SUCCESS,
    data
  }
}
export function getProfileFailure(error) {
  return {
    type: GET_PROFILE.FAILURE,
    error,
  }
}

export function updateProfile(profileData) {
  return {
    type: UPDATE_PROFILE.REQUEST,
    profileData,
  }
}
export function updateProfileSuccess(data) {
  return {
    type: UPDATE_PROFILE.SUCCESS,
    data
  }
}
export function updateProfileFailure(error) {
  return {
    type: UPDATE_PROFILE.FAILURE,
    error,
  }
}

export function changePassword(passwordData) {
  return {
    type: CHANGE_PASSWORD.REQUEST,
    passwordData,
  }
}
export function changePasswordSuccess(data) {
  return {
    type: CHANGE_PASSWORD.SUCCESS,
    data
  }
}
export function changePasswordFailure(error) {
  return {
    type: CHANGE_PASSWORD.FAILURE,
    error,
  }
}
