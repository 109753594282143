import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { persistor } from '../../store/storeConfig';
import { logout } from '../../store/actions/auth_actions';
import { updateProfile } from "../../store/actions/user_actions";
import { Loader } from '../../components';
import {FILE_SIZE_LIMIT} from '../../constants';
import ic_user from '../../assets/icons/ic_user_white.svg';

import './ProfilePage.scss';
import withRouter from "../../wrapper/withRouter";


class ProfilePage extends Component {
  state = {
    isChangePassword: false,
    isLoggingOut: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, history, router } = this.props;
    const { navigate } = router;

    if (prevState.isLoggingOut && prevProps.auth.isFetching && !auth.isFetching) {
      navigate(`/login`);
      persistor.purge();
      setTimeout(() => {
        localStorage.clear();
        window.location.reload(true);
      }, 1000);
    }
  }

  logout = (e) => {
    this.props.logout()
    this.setState({isLoggingOut: true})
  }

  changePassword = (e) => {
    const { router } = this.props;
    const { navigate } = router;
    navigate(`/change-password`)
  }

  changePhoto = (e) => {
    const file = e.target.files[0];
    if (file.size <= FILE_SIZE_LIMIT) {
      this.props.updateProfile({profile_picture: file});
    } else {
      toast(`Die Datei ${file.name} ist zu gross`);
    }
  }

  render() {
    const { user, router } = this.props;
    const { params } = router;
    const wasPasswordChanged = params.success;

    if (!user.user) {
      return <Loader />;
    }

    const profilePic = (user.user && user.user.profile_picture) || ic_user;

    return (
      <div className="profilePage">
        <Container>
          <Row>
            <Col md={12}>
              <div className={classnames("user-img",
                { 'has-profile-image': user.user && user.user.profile_picture },
              )}
              >
                <img alt="" style={({backgroundImage: `url(${profilePic})`})} />
                <label htmlFor="upload-photo">
                  <div className="img-caption">
                    <span>
                      Ändern
                    </span>
                    <input type="file" id="upload-photo" onChange={this.changePhoto} />
                  </div>
                </label>
              </div>
              <span className="user-name">
                {user.user.name}
              </span>
              <Button variant="secondary" onClick={this.logout}>
                Abmelden
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <span className="user-apt">
                Wohnung
                &nbsp;
                {user.user.apartment_number.split("-")[1] !== undefined ? user.user.apartment_number.split("-")[1] : user.user.apartment_number}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="user-hr" />
          </Row>
          <Row>
            <Col md={12}>
              <span className="user-email">
                {user.user.email}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button type="primary" onClick={this.changePassword}>
                Passwort ändern
              </Button>
            </Col>
          </Row>
          {wasPasswordChanged &&
          <Row>
            <Col md={12}>
              <p className="password-changed">
                Ihr Passwort wurde erfolgreich geändert.
              </p>
            </Col>
          </Row>
          }
        </Container>
      </div>
    );
  }
}

ProfilePage.defaultProps = {
};

const mapStateToProps = state => ({
  user: state.user,
  auth: state.auth,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    logout,
    updateProfile,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfilePage));
