import React, { Component } from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, Button } from 'react-bootstrap';

import { updateProfile } from "../../store/actions/user_actions";
import AuthService from "../../services/auth.service";

import './styles.scss';


class LanguageToggle extends Component {
  state = {
    show: false
  }

  getClass = (languageCode) => {
    const { activeLanguage } = this.props;
    return languageCode === activeLanguage.code ? 'active' : ''
  };

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  updateLanguage = (code) => {
    const { setActiveLanguage, updateProfile } = this.props;

    setActiveLanguage(code);
    this.setState({ show: false });

    if (AuthService.isAuthenticated) {
      updateProfile({language: code.toUpperCase()});
    }
  }

  render() {
    const {languages, activeLanguage} = this.props;

    return (
      <>
        {/* <Button
          variant="secondary"
          className="language-button"
          onClick={this.handleShow}
        >
          {activeLanguage.name}
        </Button> */}

        <Modal centered className="language-modal" show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <ul className="selector">
              {languages.map(lang =>
                <li key={lang.code}>
                  <Button
                    variant="link"
                    className={this.getClass(lang.code)}
                    onClick={() => this.updateLanguage(lang.code)}
                  >
                    {lang.name}
                  </Button>
                </li>
              )}
            </ul>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

LanguageToggle.propTypes = {
  languages: PropTypes.array.isRequired,
  activeLanguage: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  setActiveLanguage: PropTypes.func.isRequired,
};

LanguageToggle.defaultProps = {
  activeLanguage: {
    name: '',
    code: '',
    active: true,
  }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    updateProfile,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LanguageToggle);
