import { all, fork, take } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';

import authSagas from './auth_sagas';
import userSagas from './user_sagas';
import documentsSagas from './documents_sagas';
import phonesSagas from './phones_sagas';
import servicesSagas from './services_sagas';
import locationSagas from './location_sagas';
import messagesSagas from './messages_sagas';
import analyticsSagas from './analytics_sagas';
import prefetchSagas from './prefetch_sagas';
import blackboardSagas from './blackboard_sagas';

export default function* rootSaga() {
  yield take(REHYDRATE); // Wait for rehydrate to prevent sagas from running with empty store
  yield all([
    fork(authSagas),
    fork(userSagas),
    fork(documentsSagas),
    fork(phonesSagas),
    fork(servicesSagas),
    fork(locationSagas),
    fork(messagesSagas),
    fork(analyticsSagas),
    fork(prefetchSagas),
    fork(blackboardSagas),
  ]);
};
