import createRequestTypes from '../../components/requestsTypes';

export const GET_MESSAGES = createRequestTypes('GET_MESSAGES');
export const GET_COMMENTS = createRequestTypes('GET_COMMENTS');
export const CREATE_MESSAGE = createRequestTypes('CREATE_MESSAGE');
export const LIKE_MESSAGE = createRequestTypes('LIKE_MESSAGE');
export const CREATE_COMMENT = createRequestTypes('CREATE_COMMENT');
export const CREATE_MESSAGE_DOCUMENT = createRequestTypes('CREATE_MESSAGE_DOCUMENT');

export function getMessages(page) {
  return {
    type: GET_MESSAGES.REQUEST,
    page
  }
}
export function getMessagesSuccess(data) {
  return {
    type: GET_MESSAGES.SUCCESS,
    data
  }
}
export function getMessagesFailure(error) {
  return {
    type: GET_MESSAGES.FAILURE,
    error,
  }
}

export function getComments(messageId) {
  return {
    type: GET_COMMENTS.REQUEST,
    messageId
  }
}
export function getCommentsSuccess(data) {
  return {
    type: GET_COMMENTS.SUCCESS,
    data
  }
}
export function getCommentsFailure(error) {
  return {
    type: GET_COMMENTS.FAILURE,
    error,
  }
}

export function createMessage(message) {
  return {
    type: CREATE_MESSAGE.REQUEST,
    message,
  }
}
export function createMessageSuccess(data) {
  return {
    type: CREATE_MESSAGE.SUCCESS,
    data
  }
}
export function createMessageFailure(error) {
  return {
    type: CREATE_MESSAGE.FAILURE,
    error,
  }
}

export function likeMessage(likeData) {
  return {
    type: LIKE_MESSAGE.REQUEST,
    likeData,
  }
}
export function likeMessageSuccess(data) {
  return {
    type: LIKE_MESSAGE.SUCCESS,
    data
  }
}
export function likeMessageFailure(error) {
  return {
    type: LIKE_MESSAGE.FAILURE,
    error,
  }
}

export function createComment(commentData) {
  return {
    type: CREATE_COMMENT.REQUEST,
    commentData,
  }
}
export function createCommentSuccess(data) {
  return {
    type: CREATE_COMMENT.SUCCESS,
    data
  }
}
export function createCommentFailure(error) {
  return {
    type: CREATE_COMMENT.FAILURE,
    error,
  }
}

export function createMessageDocument(filesData) {
  return {
    type: CREATE_MESSAGE_DOCUMENT.REQUEST,
    filesData,
  }
}
export function createMessageDocumentSuccess(data) {
  return {
    type: CREATE_MESSAGE_DOCUMENT.SUCCESS,
    data
  }
}
export function createMessageDocumentFailure(error) {
  return {
    type: CREATE_MESSAGE_DOCUMENT.FAILURE,
    error,
  }
}
