import { all, call, put, fork, takeLatest } from 'redux-saga/effects';

import { getProviders } from "../api/services";

import {
  GET_DOCUMENTS,
} from '../actions/documents_actions';
import {
  GET_PHONES,
} from '../actions/phones_actions';
import {
  GET_SERVICES,
  getProvidersSuccess,
} from '../actions/services_actions';

// eslint-disable-next-line
function* getDocumentsImagesSaga(action) {
  try {
    action.data.results.forEach((document) => {
      const img = new Image();
      img.src = document.icon;
    });
  } catch (error) {
  }
}

function* getProvidersSaga(action) {
  try {
    const results = yield all(action.data.results.map(service =>
      call(getProviders, service.id)
    ));
    yield all(results.map( (provider, idx) =>
      put(getProvidersSuccess({...provider.data, id: action.data.results[idx].id}))
    ));
  } catch (error) {
  }
}

const getDocumentsImagesWatcher = function* getDocumentsImagesWatcher() {
  yield takeLatest([
    GET_DOCUMENTS.SUCCESS,
    GET_PHONES.SUCCESS,
    GET_SERVICES.SUCCESS,
  ], getDocumentsImagesSaga);
};
const getProvidersWatcher = function* getProvidersWatcher() {
  yield takeLatest(GET_SERVICES.SUCCESS, getProvidersSaga);
};

export default function* authSaga() {
  yield all([
    fork(getDocumentsImagesWatcher),
    fork(getProvidersWatcher),
  ]);
}
