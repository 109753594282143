import { all, fork, call, delay, put, takeLatest } from 'redux-saga/effects';

import { getBlackboardOpend, setBlackboardOpend } from '../api/blackboard';

import { 
  GET_BLACKBOARD_OPEND, 
  getBlackboardOpendSuccess,
  getBlackboardOpendFailure,
  SET_BLACKBOARD_OPEND,
  setBlackboardOpendSuccess,
  setBlackboardOpendFailure, 
} from '../actions/blackboard_action'

function* getBlackboardOpendSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(getBlackboardOpend, action.userId);
    yield put(getBlackboardOpendSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(getBlackboardOpendFailure(processedError || error));
  }
}

function* setBlackboardOpendSaga(action) {
  yield delay( 500);
  try {
    const { data } = yield call(setBlackboardOpend, action.userId);
    yield put(setBlackboardOpendSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {}
    yield put(setBlackboardOpendFailure(processedError || error));
  }
}


const getBlackboardOpendWatcher = function* getBlackboardOpendWatcher() {
  yield takeLatest(GET_BLACKBOARD_OPEND.REQUEST, getBlackboardOpendSaga);
};

const setBlackboardOpendWatcher = function* setBlackboardOpendWatcher() {
  yield takeLatest(SET_BLACKBOARD_OPEND.REQUEST, setBlackboardOpendSaga);
};


export default function* authSaga() {
  yield all([
    fork(getBlackboardOpendWatcher),
    fork(setBlackboardOpendWatcher),
  ]);
}