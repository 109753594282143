import createRequestTypes from '../../components/requestsTypes';

export const GET_PHONES = createRequestTypes('GET_PHONES');

export function getPhones() {
  return {
    type: GET_PHONES.REQUEST,
  }
}

export function getPhonesSuccess(data) {
  return {
    type: GET_PHONES.SUCCESS,
    data
  }
}

export function getPhonesFailure(error) {
  return {
    type: GET_PHONES.FAILURE,
    error,
  }
}
