import React, { Component } from 'react';


class Img extends Component {
  render() {
    let { src, altSrc, srcSet, altSrcSet } = this.props;
    if (!altSrc) {
      altSrc = src;
      src = src.replace(/\.png$/,'.webp');
    }
    return (
      <picture>
        <source srcSet={srcSet} type="image/webp" />
        <source srcSet={altSrcSet} type="image/jpeg" />
        <img src={altSrc} alt="" />
      </picture>
    );
  }
}

export default Img;
