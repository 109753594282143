import {API_URL} from '../../constants';
import http from './http-decorator';
import AuthService from '../../services/auth.service';

export const setBlackboardOpend = (userId) => http(fetch(
    `${API_URL}/api/blackboard_opened/${userId}/`,
    {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${AuthService.token}`,
      },
      body: JSON.stringify({"date_blackboard_opened": new Date()})
    },
));

export const getBlackboardOpend = (userId) => http(fetch(
  `${API_URL}/api/blackboard_opened/${userId}/`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));