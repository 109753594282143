import {API_URL} from '../../constants';
import http from './http-decorator';
import AuthService from '../../services/auth.service';


export const getMessages = (page) => http(fetch(
  `${API_URL}/api/messages/?page=${page || 1}`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const getComments = (messageId) => http(fetch(
  `${API_URL}/api/messages/${messageId}/comments/`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const createMessage = (payload) => http(fetch(
  `${API_URL}/api/messages/`,
  {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
    body: JSON.stringify({ ...payload }),
  },
));

export const likeMessage = (messageId) => http(fetch(
  `${API_URL}/api/messages/${messageId}/like/`,
  {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const unlikeMessage = (messageId) => http(fetch(
  `${API_URL}/api/messages/${messageId}/unlike/`,
  {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const createComment = (messageId, payload) => http(fetch(
  `${API_URL}/api/messages/${messageId}/comments/`,
  {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
    body: JSON.stringify({ ...payload }),
  },
));

export const createMessageDocument = (messageId, payload) => {
  const formData = new FormData();

  for (let name in payload) {
    formData.append(name, payload[ name ]);
  }

  return http(fetch(
    `${API_URL}/api/messages/${messageId}/documents/`,
    {
      method: 'POST',
      headers: {
        "Authorization": `Token ${AuthService.token}`,
      },
      body: formData,
    },
  ))
};
