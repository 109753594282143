import {API_URL} from '../../constants';
import http from './http-decorator';
import AuthService from '../../services/auth.service';


export const getProfile = () => http(fetch(
  `${API_URL}/api/profile/`,
  {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
  },
));

export const updateProfile = (payload) => {
  const formData = new FormData();

  for (let name in payload) {
    formData.append(name, payload[ name ]);
  }

  return http(fetch(
    `${API_URL}/api/profile/`,
    {
      method: 'PUT',
      headers: {
        "Authorization": `Token ${AuthService.token}`,
      },
      body: formData,
    },
  ))
};

export const changePassword = (payload) => http(fetch(
  `${API_URL}/api/profile/changepassword/`,
  {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${AuthService.token}`,
    },
    body: JSON.stringify({ ...payload }),
  },
));