import { GET_DOCUMENTS } from '../actions/documents_actions';

const initialState = {
  documents: [],
  isFetching: false,
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_DOCUMENTS.SUCCESS:
      return {
        ...state,
        documents: action.data.results,
        isFetching: false,
      };
    case GET_DOCUMENTS.FAILURE:
      return {
        ...state,
        documents: [],
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
