import createRequestTypes from '../../components/requestsTypes';

export const GET_LOCATION = createRequestTypes('GET_LOCATION');

export function getLocation() {
  return {
    type: GET_LOCATION.REQUEST,
  }
}

export function getLocationSuccess(data) {
  return {
    type: GET_LOCATION.SUCCESS,
    data
  }
}

export function getLocationFailure(error) {
  return {
    type: GET_LOCATION.FAILURE,
    error,
  }
}
