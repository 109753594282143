import React from 'react';
import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultLayout from './';
import {
  ApartmentPage,
  PhoneNumbersPage,
  HomePage,
  ServicesPage,
  ReceptionPage,
  LoginPage,
  ForgotPasswordPage,
  ProfilePage,
  ChangePasswordPage,
  BlackboardPage,
  BlackboardForm,
  MessagePage,
} from '../pages/';

class Routes extends React.Component {

  render() {
    return (
      <DefaultLayout>
        <ReactRouterRoutes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password/:token?" element={<ForgotPasswordPage />} />
          <Route path="/profile/:success?" element={<ProfilePage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/phone-numbers" element={<PhoneNumbersPage />} />
          <Route path="/apartment" element={<ApartmentPage />} />
          <Route path="/blackboard/:page?" element={<BlackboardPage />} />
          <Route path="/messages/new" element={<BlackboardForm />} />
          <Route path="/messages/view/:messageId" element={<MessagePage />} />
          <Route path="/services/:serviceId?" element={<ServicesPage />} />
          <Route path="/reception" element={<ReceptionPage />} />
          <Route path="/" element={<HomePage />} />
        </ReactRouterRoutes>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Routes);
